import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem',
          padding: '4px 8px',
          borderRadius: '4px',
        },
      },
    },
  },
});
