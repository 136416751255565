import React from 'react';
import { Button, alpha, ButtonProps } from '@mui/material';

interface ActionButtonProps extends Omit<ButtonProps, 'startIcon'> {
  icon?: React.ReactElement;
  label?: React.ReactNode;
  startIcon?: React.ReactElement;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  'aria-label'?: string;
}

const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      icon,
      label,
      startIcon,
      onClick,
      disabled = false,
      style,
      children,
      size = 'medium',
      'aria-label': ariaLabel,
      ...props
    },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        variant="text"
        startIcon={startIcon || icon || null}
        onClick={onClick}
        disabled={disabled}
        size={size}
        aria-label={ariaLabel}
        sx={{
          borderRadius: 2,
          justifyContent: 'flex-start',
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          },
          ...(style && { ...style }),
        }}
        {...props}
      >
        {label || children}
      </Button>
    );
  },
);

ActionButton.displayName = 'ActionButton';

export default ActionButton;
