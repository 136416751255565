import * as React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  Box,
  CardHeader,
  Tooltip,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CardActions,
  IconButton,
  Button,
} from '@mui/material';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/Check';
import DirectionIcon from '@mui/icons-material/Directions';
import uiStore from '../stores/ui';
import appStore from '../stores/app';
import { DIFFICULTY, DIFFICULTY_TEXT } from '../types';
import { CachedHtmlView } from '../components/CachedHtmlView';
import { getDistance } from '../utils/utils';
import { CachedImage } from '../components/CachedImage';
import { observer } from 'mobx-react-lite';
import ActionButton from '../components/ui/ActionButton';
import TripInfo from '../components/trip/TripInfo';

export default observer(function Spot() {
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);

  React.useEffect(() => {
    uiStore.appBar = { page: 'spot', id };
    window.scrollTo(0, 0);
  }, [id]);

  if (uiStore.appLoading) {
    return <div>loading</div>;
  }
  if (!tripId || !appStore.trips.spot[tripId]) {
    return <Navigate to="/home/list" />;
  }

  const { photo, title, gps, description, id_trip } =
    appStore.trips.spot[tripId];

  const isVisited = appStore.isSpotVisited(tripId);

  const distanceFromUser = Math.round(
    getDistance(gps, uiStore.currentUserPosition),
  );
  const distanceFromUserKm = distanceFromUser / 1000;

  return (
    <Box sx={{ padding: '10px', textAlign: 'center' }}>
      {!isVisited && (
        <Chip
          sx={{ marginTop: '15px', marginBottom: '20px' }}
          label="Aktivity se zobrazí po navštívení tohoto místa."
          variant="outlined"
        />
      )}
      {isVisited && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
            '& > a, button': {
              marginTop: '5px',
              marginBottom: '5px',
            },
          }}
        >
          {[...DIFFICULTY_TEXT.keys()].map((difficulty) => (
            <Activity key={difficulty} id={tripId} difficulty={difficulty} />
          ))}
        </Box>
      )}
      <Card sx={{ textAlign: 'left' }}>
        <CachedImage image={photo.photo_url} cacheName={`trip-${id_trip}`}>
          {(image) => (
            <CardMedia
              style={{ height: 220 }}
              image={image}
              title={photo.photo_name}
            />
          )}
        </CachedImage>
        <CardHeader style={{ paddingBottom: 0 }} title={title} />

        <CardContent>
          <CardActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <ActionButton
              style={{ padding: 1.5, color: isVisited ? 'default' : 'primary' }}
              aria-label="Navigace"
              size="small"
              startIcon={<DirectionIcon />}
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/dir/?api=1&destination=${gps.lat},${gps.lng}&dir_action=navigate&travelmode=walking`,
                )
              }
            >
              &nbsp; Online navigace
              <br />
              Google
            </ActionButton>

            {isVisited ? (
              <IconButton
                style={{ paddingTop: 0, marginLeft: 'auto' }}
                aria-label="Místo bylo navštíveno"
                size="large"
              >
                <span style={{ fontSize: 14 }}>navštíveno</span>&nbsp;{' '}
                <DoneIcon />
              </IconButton>
            ) : (
              distanceFromUser > 0 && (
                <TripInfo
                  tooltip={`Vzdálenost přímou čarou: ${distanceFromUserKm.toFixed(
                    1,
                  )}km`}
                  label={`${distanceFromUserKm.toFixed(1)}km`}
                  icon={<WalkIcon />}
                />
              )
            )}
          </CardActions>

          <CachedHtmlView cacheName={`trip-${id_trip}`}>
            {description}
          </CachedHtmlView>
        </CardContent>
      </Card>
    </Box>
  );
});

const Activity = observer(function Activity({
  id,
  difficulty,
}: {
  id: number;
  difficulty: DIFFICULTY;
}) {
  const task = appStore.trips.spot[id].activity?.[difficulty];
  if (!task) {
    return (
      <Button disabled variant="outlined" color="primary">
        Žádná aktivita pro tuto obtížnost
      </Button>
    );
  }

  return (
    <Button
      component={Link}
      to={`/activity/${id}/${difficulty}`}
      variant="outlined"
      color="primary"
    >
      {DIFFICULTY_TEXT.get(difficulty)}
      {appStore.isSpotSolved(id, difficulty) && <CheckIcon />}
    </Button>
  );
});
