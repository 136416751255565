import * as React from 'react';
import {
  Card,
  Button,
  TextField,
  CardActions,
  CardActionArea,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import appStore from '../stores/app';
import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
  tripId: number;
};

export default observer(function Popup({ tripId, onClose }: Props) {
  const [password, setPassword] = React.useState('');
  const [wrongPassword, setWrongPassword] = React.useState(false);

  const checkPassword = React.useCallback(() => {
    if (btoa(password) === appStore.trips.collection[tripId].password) {
      appStore.unlockTrip(tripId);
    } else {
      setWrongPassword(true);
    }
  }, [password, tripId]);

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Odemknout trasu
          </Typography>
          {wrongPassword && (
            <Chip
              style={{ marginTop: '20px', marginBottom: '20px' }}
              label="Toto heslo není správné"
              variant="outlined"
              color="secondary"
            />
          )}
          <Typography component="p" gutterBottom>
            Prosím, zadejte heslo pro odemknutí trasy.
            <TextField
              style={{ width: '100%' }}
              id="standard-name"
              label="Heslo"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              autoFocus
            />
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={checkPassword}
        >
          Odemknout
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Zpět
        </Button>
      </CardActions>
    </Card>
  );
});
