import * as React from 'react';
import appStore from '../../stores/app';
import Map from '../../components/map/Map';
import { SpotCard } from '../../components/SpotCard';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

export const TripMap = observer(function TripMap() {
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);
  const spots = appStore.trips.collection[tripId]?.spots.map(
    (id) => appStore.trips.spot[id],
  );
  const spotsAvailable =
    appStore.trips.collection[tripId].spotsAlwaysShown ||
    appStore.trips.showSpots[tripId]
      ? spots
      : spots.filter((spot, i) => {
          if (
            i == 0 ||
            appStore.isSpotVisited(spot.id) ||
            (!appStore.isSpotVisited(spot.id) &&
              appStore.isSpotVisited(spots[i - 1].id))
          ) {
            return true;
          }
        });

  return (
    <Map
      spots={spotsAvailable}
      popup={SpotCard}
      isSpotVisited={(id) => appStore.isSpotVisited(id)}
      tripId={tripId}
    />
  );
});
