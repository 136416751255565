import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Trips from './Trips';
import './admin-dashboard.css';

export default function TripView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{
          mt: isMobile ? 2 : 4,
          mb: isMobile ? 2 : 4,
          px: isMobile ? 1 : 3,
        }}
      >
        <Grid container spacing={isMobile ? 2 : 3}>
          {/* Trips */}
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Trips />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
