import React from 'react';
import uiStore from '../../stores/ui';
import { Tooltip, IconButton, styled } from '@mui/material';
import { GpsFixed, GpsNotFixed, GpsOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useToastContext } from '../../context/ToastContext';
import { observer } from 'mobx-react-lite';
import appStore from '../../stores/app';

const IconWrapper = styled(IconButton)({
  padding: 8,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  transition: 'background-color 0.3s',
});

const ErrorText = styled('span')({
  marginLeft: 8,
  fontSize: 16,
});

export default observer(function GpsStatusIndicator() {
  const { showToast } = useToastContext();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  React.useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'state') {
        try {
          const newState = e.newValue ? JSON.parse(e.newValue) : null;
          const isActive = newState?.activeTrip;

          if (isActive && isActive !== appStore.activeTrip) {
            showToast('GPS sledování je aktivní');
          }
        } catch (error) {
          console.error('Error parsing state:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [showToast]);

  if (!appStore.activeTrip) {
    return (
      <Tooltip
        title="GPS sledování neaktivní - aktivujte trasu"
        placement="left"
        arrow
        enterTouchDelay={1}
      >
        <IconWrapper color="inherit" disableRipple>
          <GpsOff color="inherit" />
        </IconWrapper>
      </Tooltip>
    );
  }

  if (!uiStore.gpsAvailable) {
    const tooltipTitle = isMobile
      ? 'GPS není k dispozici'
      : 'Pro přesnější GPS sledování použijte mobilní zařízení';

    const errorText = isMobile
      ? 'je třeba povolit lokalizaci GPS!'
      : 'GPS není k dispozici';

    return (
      <Tooltip title={tooltipTitle} placement="left" arrow enterTouchDelay={1}>
        <IconButton
          color="inherit"
          aria-label="Chyba GPS"
          component={Link}
          to="/help"
          size="small"
        >
          <GpsOff color="inherit" />
          <ErrorText>{errorText}</ErrorText>
        </IconButton>
      </Tooltip>
    );
  }

  const accuracyThreshold = 100;
  const hasAccurateSignal =
    uiStore.currentUserPosition?.accuracy &&
    uiStore.currentUserPosition.accuracy <= accuracyThreshold;

  const accuracyTooltip = isMobile
    ? hasAccurateSignal
      ? 'GPS sledování aktivní'
      : 'GPS signál je nepřesný'
    : 'Pro přesnější GPS sledování použijte mobilní zařízení';

  console.log(hasAccurateSignal);
  return (
    <Tooltip title={accuracyTooltip} placement="left" arrow enterTouchDelay={1}>
      <IconWrapper color="inherit" disableRipple>
        {hasAccurateSignal ? (
          <GpsFixed color="inherit" />
        ) : (
          <GpsNotFixed color="inherit" />
        )}
      </IconWrapper>
    </Tooltip>
  );
});
