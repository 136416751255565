import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Divider,
  Rating,
} from '@mui/material';
import uiStore from '../stores/ui';
import appStore from '../stores/app';
import { CachedImage } from '../components/CachedImage';
import { observer } from 'mobx-react-lite';
import { setRating } from '../backend';

export default observer(function Popup({ onClose }: { onClose: () => void }) {
  if (!uiStore.reachedSpot) return null;
  if (!uiStore.finishedTrip) return null;

  const spot = appStore.trips.spot[uiStore.reachedSpot];
  const trip = appStore.trips.collection[uiStore.finishedTrip];

  const navigate = useNavigate();

  const [userRating, setUserRating] = React.useState<number | null>(null);

  const exit = () => {
    if (userRating != null && appStore.user != null) {
      setRating(userRating, trip.id, appStore.user);
    }
    uiStore.closeReachedSpotPopup();
    appStore.deactivateTrip();
    onClose();
  };

  return (
    <Card>
      <CardActionArea>
        <CachedImage
          image={trip.profile_photo.file_url}
          cacheName={`trip-${trip.id}`}
        >
          {(image) => (
            <CardMedia
              style={{ height: 160 }}
              image={image}
              title={spot.title}
              component={Link}
              to={`/trip/${uiStore.finishedTrip}`}
            />
          )}
        </CachedImage>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {trip.title}
          </Typography>
          <Typography component="p" gutterBottom>
            {`Gratulujeme, úspěšně jste ukončili trasu ${trip.title}! Nyní můžete přejít na kartu posledního místa a zúčastnit se aktivity.`}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions
        sx={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          flexDirection: 'column',
          '& > button, a': {
            margin: '4px',
          },
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            mb: 1,
            px: 1,
          }}
          variant="body1"
        >
          Jak se vám trasa líbila?
        </Typography>
        <Divider />
        <Rating
          name="simple-controlled"
          size="large"
          value={userRating}
          onChange={(event, newValue) => {
            setUserRating(newValue);
          }}
          sx={{
            my: 1.5,
            fontSize: '3rem',
          }}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            if (userRating != null && appStore.user != null) {
              setRating(userRating, trip.id, appStore.user);
            }
            appStore.deactivateTrip();
            navigate(`/spot/${uiStore.reachedSpot}`);
          }}
        >
          Přejít na detail místa
        </Button>
        <Button size="small" variant="outlined" onClick={exit}>
          Zavřít
        </Button>
      </CardActions>
    </Card>
  );
});
