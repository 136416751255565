import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import uiStore from '../../stores/ui';

import SubMenu from '../../components/layout/SubMenu';
import PasswordPopup from '../../popups/PasswordPopup';
import { fetchTripDetails } from '../../backend';
import appStore from '../../stores/app';
import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import Popup from '../../components/ui/Popup';
import GeoListener from '../../components/map/GeoListener';
import GeoListenerWithSimulator from '../../components/map/GeoListenerSimulator';

export default observer(function Trip() {
  const navigate = useNavigate();
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (!id) return;

    fetchTripDetails(tripId);

    uiStore.appBar = { page: 'trip', id };
    window.scrollTo(0, uiStore.scroll.trip);
    return () => {
      uiStore.scroll.trip = window.scrollY;
    };
  }, [id, tripId]);

  if (!id || uiStore.appLoading || !appStore.trips.collection[id]) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CircularProgress size={100} />
      </Box>
    );
  }

  const isLocked =
    appStore.trips.collection[id].password && !appStore.trips.unlocked[id];

  if (isLocked) {
    return (
      <Popup
        open
        content={PasswordPopup}
        data={{ onClose: () => navigate(-1), tripId }}
      />
    );
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 1 }}
    >
      <GeoListener tripId={tripId} />

      {appStore.admin && !isMobile && (
        <GeoListenerWithSimulator tripId={tripId} />
      )}

      <SubMenu
        items={{ description: 'Popis', map: 'Mapa', spots: 'Stanoviště' }}
      />
      <Outlet />
    </Box>
  );
});
