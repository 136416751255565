import React from 'react';
import {
  Dialog,
  Box,
  Typography,
  Rating,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface RatingDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (rating: number | null) => void;
  onChange: (rating: number | null) => void;
  value: number | null;
  showSuccess: boolean;
}

export const RatingDialog: React.FC<RatingDialogProps> = ({
  open,
  onClose,
  onSubmit,
  value,
  onChange,
  showSuccess,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 2,
      },
    }}
  >
    <IconButton
      sx={{ position: 'absolute', top: 3, right: 3 }}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
    <Box
      sx={{
        padding: '6px',
        marginTop: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {showSuccess ? (
        <Typography fontSize={18} color="success.main" sx={{ my: 2 }}>
          Děkujeme za hodnocení.
        </Typography>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Přidat hodnocení trasy
          </Typography>
          <Rating
            size="large"
            value={value}
            onChange={(_, value) => value && onChange(value)}
            sx={{ fontSize: '3rem', my: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => value && onSubmit(value)}
            disabled={!value}
            sx={{ mt: 2 }}
          >
            Odeslat hodnocení
          </Button>
        </>
      )}
    </Box>
  </Dialog>
);
