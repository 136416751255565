import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Toolbar,
  useTheme,
  useMediaQuery,
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  styled,
  Divider,
} from '@mui/material';
import Title from './Title';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import appStore from '../../stores/app';
import { deleteTrip, getTrips, fetchTripDetails } from '../../backend';
import { formatDate } from '../../utils/formatDate';
import { observer } from 'mobx-react-lite';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const MobileCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const Trips = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const deleteTripConfirm = async (tripId: number) => {
    const canDelete = window.confirm(
      'Jste si jisti, že chcete smazat trasu? Změny nelze vrátit zpět.',
    );
    if (canDelete) {
      await deleteTrip(tripId);
      window.alert('Trasa úspěšne smazána.');
      await getTrips();
    }
  };

  const editTrip = (tripId: number) => {
    fetchTripDetails(tripId);
    navigate(`/admin/editTrip/${tripId}`);
  };

  const MobileView = () => (
    <Box sx={{ mt: 2 }}>
      {appStore.sortedTripList?.map((tripId) => (
        <MobileCard key={tripId} variant="outlined">
          <CardContent>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              marginBottom={1.5}
            >
              <Typography variant="subtitle2" color="text.secondary">
                ID: {tripId}
              </Typography>

              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => editTrip(tripId)}
                  size="small"
                  sx={{ color: 'primary.main' }}
                >
                  <ModeEditIcon />
                </IconButton>
                <IconButton
                  onClick={() => deleteTripConfirm(tripId)}
                  size="small"
                  sx={{ color: 'error.main' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h6" component="div">
                {appStore.trips.collection[tripId].title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Autor: {appStore.trips.collection[tripId].author}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Datum změny:{' '}
                {formatDate(appStore.trips.collection[tripId].timestamp_update)}
              </Typography>
            </Stack>
          </CardContent>
        </MobileCard>
      ))}
    </Box>
  );

  // Desktop view component
  const DesktopView = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>ID</StyledTableCell>
          <StyledTableCell>Datum změny</StyledTableCell>
          <StyledTableCell>Název</StyledTableCell>
          <StyledTableCell>Autor</StyledTableCell>
          <StyledTableCell align="right">Upravit</StyledTableCell>
          <StyledTableCell align="right">Smazat</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {appStore.sortedTripList?.map((tripId) => (
          <TableRow key={tripId}>
            <StyledTableCell>{tripId}</StyledTableCell>
            <StyledTableCell>
              {formatDate(appStore.trips.collection[tripId].timestamp_update)}
            </StyledTableCell>
            <StyledTableCell>
              {appStore.trips.collection[tripId].title}
            </StyledTableCell>
            <StyledTableCell>
              {appStore.trips.collection[tripId].author}
            </StyledTableCell>
            <StyledTableCell align="right">
              <IconButton
                onClick={() => editTrip(tripId)}
                size="medium"
                sx={{ color: 'primary.main' }}
              >
                <ModeEditIcon />
              </IconButton>
            </StyledTableCell>
            <StyledTableCell align="right">
              <IconButton
                onClick={() => deleteTripConfirm(tripId)}
                size="medium"
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          px: isMobile ? 1 : 2,
          minHeight: isMobile ? 48 : 64,
        }}
      >
        <Title>Seznam cest</Title>
        <IconButton
          style={{ backgroundColor: '#006bcc' }}
          color="inherit"
          aria-label="Add"
          size="medium"
          onClick={() => navigate(`/admin/editTrip/new`)}
        >
          <AddIcon sx={{ color: 'white' }} fontSize="medium" />
        </IconButton>
      </Toolbar>
      {isMobile ? <MobileView /> : <DesktopView />}
    </React.Fragment>
  );
});

export default Trips;
