import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ChildIcon from '@mui/icons-material/ChildFriendly';
import uiStore from '../stores/ui';
import { Link } from 'react-router-dom';
import { purgeApplicationData } from '../storage';
import { observer } from 'mobx-react-lite';

const HelpCard = () => {
  return (
    <Card>
      <CardContent>
        <h2>Jak si LApku zahrát?</h2>
        <Typography component="p">
          <ol>
            <li>
              Přečtěte si nápovědu - takže již nyní jste na dobré cestě. LApka
              vás chválí :-)
            </li>
            <li>
              Podívejte se na seznam <a href="/home/list">tras/cest</a>. Někdy
              začínají a končí na jednom místě, jindy vás dovedou ze startu do
              cíle. LApka má nyní 7 tras/cest. Vyberte si, který se vám líbí.
              Třeba je ve vašem okolí nebo někde, kde jste v Brně ještě nebyli.
              Někdy je trasa nenáročná, někdy se trošku zapotíte. K dobrému
              výběru vám pomůže mapa, krátký popis cesty, délka v kilometrech,
              odhad času na projití cesty a také symboly.
            </li>
            <li>
              Symboly:
              <br />
              <ChildIcon /> Kočárek - trasu zvládnete i s kočárkem, ideálně
              trochu sportovnějším.
              <br />
              <DirectionsBikeIcon /> Kolo - trasu zvládnete i na kole, včetně
              dětí, kterým nevadí trochu šlápnout do pedálů, nebo tlačit do
              kopce, kde to na kole moc nejde :-)
            </li>
            <li>
              Příprava na cestu - u každé cesty najdete informace o trase a
              případně co s sebou. Úkoly jsou vymyšlené většinou tak, aby k
              jejich plnění stačilo to, co můžete mít běžně sebou. Někdy se hodí
              tužka a papír, někdy i něco dalšího. Je také dobré se podívat jak
              se dostanete na místo startu a také jak se dostanete zpátky z
              cíle.
              <br />
              Použít můžete{' '}
              <a href="https://play.google.com/store/apps/details?id=cz.dpmb.dpmbinfo">
                aplikaci DPMBinfo
              </a>
              .
            </li>
            <li>
              Ideální je začít trasu na stanovišti číslo 1 a postupovat po
              stanovištích vzestupně. Jednotlivá stanoviště můžete hledat pomocí
              mapy, nebo využít nějakou svou další aplikaci k navigaci, to
              necháváme na vás. Zvládnout trasu můžete již tím, že ji pouze
              projdete a jen necháte systém načíst vaši polohu a ověřit tak, že
              jste prošli všechny stanoviště.
            </li>
            <li>
              Každá cesta má právě 10 zastavení. Jediné, co je důležité, projít
              cestu celou. A ani to nemusíte zvládnout za jeden den. Klidně
              výlet přerušte a vraťte se později. Místo, kde už jste byli, bude
              v mapě svítit červeně, co vás ještě čeká je na mapě pořád bílé.
            </li>
            <li>
              Na každém stanovišti se můžete rozhodnout, zda chcete něco více:
              <ul>
                <li>
                  Pod označením “<strong>znalec</strong>” se dozvědět informace
                  o konkrétním místě a také někdy odpovědět na otázky, které se
                  k těmto místům vztahují.
                </li>
                <li>
                  Pod označením “<strong>hádankář</strong>” zkusíte vyřešit
                  nějakou šifru, či úkol. Otázky jsou buď šifrovány, či je nutno
                  pro vyluštění něco zjistit.
                </li>
                <li>
                  Pod označením “<strong>mrňous</strong>” vás čekají návrhy na
                  drobné hry nebo možnosti co by se dalo s rodiči nebo kamarády
                  na daném místě dělat, o čem si povídat, nebo se něco dozvědět.
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" href="/home">
          Vyrazit na cestu
        </Button>
      </CardActions>
    </Card>
  );
};

export default observer(function Help() {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  React.useEffect(() => {
    uiStore.appBar = {
      page: 'help',
    };

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: '10px',
          '& > div': {
            marginBottom: '10px',
          },
        }}
      >
        <Card>
          <CardContent>
            <h2>Jak nainstalovat LApku do telefonu?</h2>
            <Typography component="p">
              Stačí si aplikaci přidat na plochu a můžete ji používat jako
              ostatní aplikace, které máte nainstalované v telefonu.
            </Typography>

            <Typography component="p">
              Webový prohlížeč sám nabídne vložení aplikace na domovskou
              stránku. Případně využijte menu webového prohlížeče a vyhledejte
              možnost &quot;Přidat na domovskou obrazovku&quot; Je třeba pouze{' '}
              <strong>povolit přístup k zjišťování polohy pomocí GPS</strong> a
              aplikace bude fungovat.
            </Typography>

            <h4>Máte problém se zjištěním polohy pomocí GPS?</h4>
            <Typography component="p">
              Pokud webový prohlížeč nemá přístup k poloze GPS, je pro používání
              LApky třeba toto oprávnění přidělit přímo webovému přohlížeči. Na
              systému Android se jedná většinou o Google Chrome, systémy iOS
              potom mají prohlížeč Safari.
            </Typography>
          </CardContent>
        </Card>

        <HelpCard />

        <br />
        <hr />
        <h3>Resetování aplikace</h3>
        <Typography component="p">
          V případě, že chcete odebrat všechny záznamy o navštívených místech a
          cestách, můžete kliknout na tlačítko níže. TEST
        </Typography>
        <br />
        <Button
          component={Link}
          to="/"
          onClick={handleOpenDialog}
          size="small"
          variant="outlined"
        >
          Resetovat aplikaci (místa, cesty apod.)
        </Button>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Resetovat aplikaci'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opravdu chcete resetovat aplikaci? Tímto krokem smažete všechny
            záznamy o navštívených místech a cestách.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            Zrušit
          </Button>
          <Button
            onClick={() => {
              purgeApplicationData();
              handleCloseDialog();
            }}
            color="error"
            variant="outlined"
            autoFocus
          >
            Resetovat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
