import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'leaflet/dist/leaflet.css';
import App from './App';
import { register, updateServiceWorker } from './serviceWorkerRegistration';
import * as Sentry from '@sentry/browser';

const IS_DEV = process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: IS_DEV
    ? 'https://8ddd69587c414156be64d302d21dfed3@app.glitchtip.com/8663'
    : 'https://06269a9948f34970bbf959a6edca8949@app.glitchtip.com/5835',
});

const el = document.getElementById('root');
if (el) {
  createRoot(el).render(<App />);
}
register({
  onUpdate: () => {
    window.confirm('Dostupná je nová verze LApky. Aplikace se aktualizuje.');
    updateServiceWorker();
  },
});
