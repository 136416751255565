import * as React from 'react';
import L from 'leaflet';
// @ts-expect-error React-leaflet's typedefs are too new
import { Map, Circle, Marker, Popup } from 'react-leaflet';
import {
  Box,
  GlobalStyles,
  Button,
  CardHeader,
  Card,
  CardActions,
  CardMedia,
} from '@mui/material';
import uiStore from '../../stores/ui';
import { optimiseImageUrl } from '../../utils/utils';
import { MAP_SETTINGS, OFFICES } from '../../constants';
import CachedLayer from '../CachedLayer';
import type { Spot } from '../../types';
import { observer } from 'mobx-react-lite';

type Props = {
  spots: {
    id: number;
    gps: Spot['gps'];
    serial_number: number;
  }[];
  isSpotVisited: (x: number) => boolean;
  popup: React.ComponentType<{ id: number }>;
  tripId?: number;
  isTripView?: boolean;
};

function spotMarker(number: number, visited = false) {
  return L.divIcon({
    className: visited ? 'visitedCustomMarker' : 'customMarker',
    html: number.toString(),
  });
}

const officeMarkerUnique = (iconPath: string) =>
  L.divIcon({
    className: 'officeMarker',
    html: `<div style="background-image: url(${iconPath}); background-size: cover; width: 100%; height: 100%;"></div>`,
  });

const userMarker = () => L.divIcon({ className: 'userMarker' });
const getGPS = ({ gps }: { gps: Spot['gps'] }) => [gps.lat, gps.lng];

export default observer(function MapComponent({
  spots,
  popup: Presenter,
  isSpotVisited,
  tripId,
  isTripView,
}: Props) {
  const user = uiStore.currentUserPosition;

  return (
    <Box sx={{ flex: 1 }}>
      <GlobalStyles
        styles={{
          '.customMarker': {
            background: 'white',
            width: '30px !important',
            height: '30px !important',
            textAlign: 'center',
            lineHeight: '30px',
            borderRadius: '15px',
            marginLeft: '-15px !important',
            marginTop: '-15px !important',
            boxShadow:
              '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            fontWeight: 'bold',
            borderColor: 'black',
          },

          '.visitedCustomMarker': {
            width: '30px !important',
            height: '30px !important',
            textAlign: 'center',
            lineHeight: '30px',
            borderRadius: '15px',
            marginLeft: '-15px !important',
            marginTop: '-15px !important',
            boxShadow:
              '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            background: '#006bcc',
            color: 'white',
            fontWeight: 'bold',
          },

          '.officeMarker': {
            background: 'transparent',
            backgroundImage: 'url(${iconPath})',
            backgroundSize: 'contain',
            width: '25px !important',
            height: '25px !important',
            textAlign: 'center',
            lineHeight: '30px',
            borderRadius: '20px',
            marginLeft: '-20px !important',
            marginTop: '-20px !important',
            boxShadow:
              '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            zIndex: '1 !important',
          },
          '.userMarker': {
            background: '#3f51b5',
            width: '16px !important',
            height: '16px !important',
            textAlign: 'center',
            lineHeight: '16px',
            borderRadius: '16px',
            marginLeft: '-7px !important',
            marginTop: '-7px !important',
            boxShadow:
              '0 4px 8px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            zIndex: '999 !important',
          },
        }}
      />

      <Map
        style={{ width: '100%', height: '100%' }}
        center={spots[0] && getGPS(spots[0])}
        zoom={
          isTripView ? MAP_SETTINGS.currentZoom : MAP_SETTINGS.currentZoom + 2
        }
        minZoom={MAP_SETTINGS.minZoom}
        maxZoom={MAP_SETTINGS.maxZoom}
        leaflet={L}
      >
        <CachedLayer
          url={MAP_SETTINGS.url}
          cacheName={tripId ? `trip-${tripId}` : 'common'}
        />
        {spots.length &&
          spots.map(({ id, gps, serial_number }) => (
            <Marker
              key={id}
              position={getGPS({ gps })}
              icon={spotMarker(serial_number, isSpotVisited(id))}
            >
              <Popup>
                <div style={{ width: '70vw', maxWidth: 300 }}>
                  <Presenter id={id} />
                </div>
              </Popup>
            </Marker>
          ))}
        {OFFICES.map(({ name, web, description, photo_url, gps, icon }) => {
          if (icon != '') {
            return (
              <Marker
                key={name}
                position={getGPS({ gps })}
                icon={officeMarkerUnique(icon)}
              >
                <Popup>
                  <div style={{ width: '70vw', maxWidth: 300 }}>
                    <Card>
                      {photo_url && (
                        <CardMedia
                          sx={{ height: '150px' }}
                          image={optimiseImageUrl(photo_url)}
                          title={name}
                        />
                      )}

                      <CardHeader title={name} subheader={description} />

                      <CardActions>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => window.open(web)}
                        >
                          Přejít na web pracoviště
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                </Popup>
              </Marker>
            );
          }
        })}
        {user && (
          <>
            <Circle center={getGPS({ gps: user })} radius={user.accuracy} />
            <Marker position={getGPS({ gps: user })} icon={userMarker()} />
          </>
        )}
      </Map>
    </Box>
  );
});
