export const BACKEND_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3333/v1'
    : process.env.REACT_APP_ENDPOINT || 'https://api.lapka.luzanky.cz/v1';

export const MAP_SETTINGS = {
  url: 'https://is.luzanky.cz/api/mapTile/outdoors/{z}/{x}/{y}.png',
  currentZoom: 13,
  minZoom: 6,
  maxZoom: 18,
};

export const GA_ID = 'G-CVDMN5N16G';

export const DISTANCE_OFFSET = 30; // meters

export const OFFICES = [
  {
    name: 'LIDICKÁ - Brno-střed',
    web: 'https://lidicka.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=01_lidick.jpg&maxWidth=800',
    description: 'Lidická 50, 602 00',

    gps: {
      lat: 49.206266,
      lng: 16.606862,
    },
    icon: '/images/offices/Lapka_LogoLidická.png',
  },
  {
    name: 'LABYRINT - Bohunice',
    web: 'https://labyrint.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=05_labyrint.jpg&maxWidth=800',
    description: 'Švermova 19, 625 00',

    gps: {
      lat: 49.167837,
      lng: 16.569905,
    },
    icon: '/images/offices/Lapka_LogoLabyrint.png',
  },
  {
    name: 'LÁNY - Bohunice',
    web: 'https://lany.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=04_lny.jpg&maxWidth=800',
    description: 'Lány 3, 625 00',

    gps: {
      lat: 49.165943,
      lng: 16.5787,
    },
    icon: '/images/offices/Lapka_LogoLány.png',
  },
  {
    name: 'LATA - Žabovřesky',
    web: 'https://lata.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=06_lata.jpg&maxWidth=800',
    description: 'Plovdivská 7, 616 00',

    gps: {
      lat: 49.21722,
      lng: 16.581496,
    },
    icon: '/images/offices/Lapka_LogoLata.png',
  },
  {
    name: 'LESNÁ - Brno-sever',
    web: 'https://lesna.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=02_lesn.jpg&maxWidth=800',
    description: 'Milénova 13, 638 00',

    gps: {
      lat: 49.229408,
      lng: 16.624127,
    },
    icon: '/images/offices/Lapka_LogoLesná.png',
  },
  {
    name: 'LINKA - Starý Lískovec',
    web: 'https://linka.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=09_linka.jpg&maxWidth=800',
    description: 'Kosmonautů 4, 625 00',

    gps: {
      lat: 49.167705,
      lng: 16.559987,
    },
    icon: '/images/offices/Lapka_LogoLinka.png',
  },
  {
    name: 'LIŠKA - Bílovice n. Svit.',
    web: 'https://liska.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=liska03.jpg&maxWidth=800',
    description: 'Žižkova 600, 664 01',

    gps: {
      lat: 49.24661,
      lng: 16.672354,
    },
    icon: '/images/offices/Lapka_LogoLiška.png',
  },
  {
    name: 'LEGATO - Kohoutovice',
    web: 'https://legato.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=07_legato.jpg&maxWidth=800',
    description: 'Stamicova 7, 623 00',

    gps: {
      lat: 49.196963,
      lng: 16.53871,
    },
    icon: '/images/offices/Lapka_LogoLegato.png',
  },
  {
    name: 'LYSKA - Nový Lískovec',
    web: 'https://lyska.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=08_lyska.jpg&maxWidth=800',
    description: 'Oblá 51c, 634 00',

    gps: {
      lat: 49.175947,
      lng: 16.555148,
    },
    icon: '/images/offices/Lapka_LogoLyska.png',
  },
  {
    name: 'LUMÍK - Kuřim',
    web: 'https://lumik.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/web/images/lumik-foto-pracoviste.jpg',
    description: 'Jungmannova 1084/1, Legionářská 338/51, 664 34',

    gps: {
      lat: 49.3030026,
      lng: 16.5277528,
    },
    icon: 'https://is.luzanky.cz/web/images/lumik-logo-avatar.svg',
  },
  {
    name: 'LOUKA - Vinohrady',
    web: 'https://louka.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=10_louka.jpg&maxWidth=800',
    description: 'Bzenecká 23, 628 00',

    gps: {
      lat: 49.20705,
      lng: 16.656885,
    },
    icon: '/images/offices/Lapka_LogoLouka.png',
  },
  {
    name: 'Lanové centrum - Brno-sever',
    web: 'https://www.lanovecentrum.cz/cs/brno/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=lanove_centrum_lesna.jpg&maxWidth=800',
    description: 'Milénova 13, 638 00',

    gps: {
      lat: 49.228148,
      lng: 16.625123,
    },
    icon: '',
  },
  {
    name: 'LORIEN - Orlické hory',
    web: 'https://lorien.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=12_lorien.jpg&maxWidth=800',
    description: 'Nekoř 253, 561 63',

    gps: {
      lat: 50.060701,
      lng: 16.537672,
    },
    icon: '',
  },
  {
    name: 'MŠ LENTILKA - Brno-střed',
    web: 'https://lentilka.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=11_lentilka.jpg&maxWidth=800',
    description: 'Žerotínovo náměstí 1, 601 82',

    gps: {
      lat: 49.199027,
      lng: 16.604841,
    },
    icon: '/images/offices/Lapka_LogoLentilka1.png',
  },
  {
    name: 'MŠ LENTILKA - Brno-střed ',
    web: 'https://lentilka.luzanky.cz/',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=kounicova.jpg&maxWidth=800',
    description: 'Kounicova 16, 602 00',

    gps: {
      lat: 49.202733,
      lng: 16.600989,
    },
    icon: '/images/offices/Lapka_LogoLentilka2.png',
  },
  {
    name: 'TZ KOZÍ HORKA - Bystrc',
    web: 'https://www.luzanky.cz/TZ-Kozi-Horka',
    photo_url:
      'https://is.luzanky.cz/api_file.php?id=23&r=lapka_trip&f=13_kozi_horka.jpg&maxWidth=800',
    description: 'Kozí Horka, 635 00',

    gps: {
      lat: 49.24108,
      lng: 16.49922,
    },
    icon: '',
  },
];
