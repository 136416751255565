import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import Boot, { purgeApplicationData } from './storage';
import { GA_ID } from './constants';

import Loader from './pages/Loader';
import Intro from './pages/Intro';
import Help from './pages/help';
import About from './pages/about';
import Luzanky from './pages/luzanky';
import Home from './pages/home';
import Trip from './pages/trip';
import Spot from './pages/spot';
import Activity from './pages/activity';
import Menu from './components/layout/Menu';
import HomeMap from './pages/home/Map';
import HomeList from './pages/home/List';
import TripDescription from './pages/trip/Description';
import TripSpots from './pages/trip/Spots';
import { TripMap } from './pages/trip/TripMap';
import Account from './pages/account';
import Username from './pages/username';
import SignIn from './pages/signIn';
import SignUp from './pages/signUp';
import Password from './pages/password';
import Verify from './pages/verify';
import ResetPassword from './pages/resetPassword';

import TripEdit from './admin/dashboard/TripEdit';
import Dashboard from './admin/dashboard/Dashboard';
import TripView from './admin/dashboard/TripView';
import SpotEdit from './admin/dashboard/SpotEdit';
import ActivityEdit from './admin/dashboard/ActivityEdit';
import { useReducer } from 'react';
import formReducer from './stores/admin';
import { initialState } from './stores/admin';
import { FormContext } from './FormContext';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Box,
  Button,
  Container,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { theme } from './theme';
import { ToastProvider } from './context/ToastContext';
import TripActive from './components/trip/TripActive';
import AdminManagement from './admin/dashboard/AddAdmin';

Boot();

ReactGA.initialize(GA_ID);

const App = () => {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }

  const [formState, dispatch] = useReducer(formReducer, initialState);

  const ErrorFallback = ({ error }: { error: Error }) => {
    const navigate = useNavigate();

    const handleResetClick = () => {
      purgeApplicationData();
      navigate('/intro');
      window.location.reload();
    };

    return (
      <div>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography align="center">
            Nastal problém při aktualizaci, pro správné fungování aplikace je
            nutný reset.
          </Typography>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleResetClick}>
            Reset
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <ToastProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '60px',
            }}
          >
            <FormContext.Provider value={{ formState: formState, dispatch }}>
              <Menu />
              <Routes>
                <Route path="admin" element={<Dashboard />}>
                  <Route path="trips" element={<TripView />} />
                  <Route path="editTrip/:id" element={<TripEdit />} />
                  <Route path="editSpot/:id" element={<SpotEdit />} />
                  <Route
                    path="editActivity/:id/:difficulty"
                    element={<ActivityEdit />}
                  />
                  <Route path="manage-admins" element={<AdminManagement />} />
                </Route>
                <Route path="/" element={<Loader />} />
                <Route path="/intro" element={<Intro />} />
                <Route path="/help" element={<Help />} />
                <Route path="/luzanky" element={<Luzanky />} />
                <Route path="/about" element={<About />} />
                <Route path="/account" element={<Account />} />
                <Route path="/username" element={<Username />} />
                <Route path="/password" element={<Password />} />
                <Route path="/signIn" element={<SignIn />} />
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/verify/:token" element={<Verify />} />
                <Route
                  path="/resetPassword/:token"
                  element={<ResetPassword />}
                />
                <Route path="/trip/:id/*" element={<Trip />}>
                  <Route
                    path="description"
                    element={
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <TripDescription />
                      </ErrorBoundary>
                    }
                  />
                  <Route path="map" element={<TripMap />} />
                  <Route path="spots" element={<TripSpots />} />
                  <Route
                    path="*"
                    element={<Navigate relative="path" to="description" />}
                  />
                </Route>
                <Route path="/spot/:id" element={<Spot />} />
                <Route
                  path="/activity/:id/:difficulty"
                  element={<Activity />}
                />
                <Route path="home" element={<Home />}>
                  <Route path="list" element={<HomeList />} />
                  <Route path="map" element={<HomeMap />} />
                  <Route path="*" element={<Navigate to="/home/list" />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              <TripActive />
            </FormContext.Provider>
          </div>
        </Router>
      </ThemeProvider>
    </ToastProvider>
  );
};

export default App;
