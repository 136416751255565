import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { changeNickname } from '../backend';
import appStore from '../stores/app';
import uiStore from '../stores/ui';
import { Dialog, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme();

export default function Username() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: 'username',
    };

    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [nickname, setNickname] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('white');

  const nicknameChange = async () => {
    if (nickname == '') {
      setMessageColor('red');
      setMessage('Přezdívka nemůže být prázdná.');
      return;
    }
    const response = await changeNickname(appStore.email, nickname);

    if (response == 0) {
      setOpen(true);
    } else if (response == 1) {
      setMessageColor('red');
      setMessage(
        'Účet se zadanou přezdívkou již existuje, prosím skuste jinou.',
      );
    } else {
      setMessageColor('red');
      setMessage(
        'Při změně přezdívky nastala nečakaná chyba. Prosím kontaktujte podporu LApky.',
      );
    }
  };

  return (
    <Box
      sx={{
        padding: '10px',
        '& > div': {
          marginBottom: '10px',
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography align="center" color={messageColor}>
              {message}
            </Typography>
            <Typography component="h1" variant="h5" sx={{ pt: 1 }}>
              {appStore.nickname ? 'Změna přezdívky' : 'Nastavení přezdívky'}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="nickname"
                name="nickname"
                autoComplete="nickname"
                autoFocus
                autoCapitalize="none"
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={nicknameChange}
              >
                Změnit
              </Button>
            </Box>
          </Box>
          <Dialog open={open} PaperProps={{ sx: { width: '80%' } }}>
            <IconButton
              sx={{ position: 'absolute', top: 3, right: 3 }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 70 }} />
              <Typography variant="h5" align="center">
                Přezdívka úspěšne změněna.
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={() => navigate(-1)}
              >
                OK
              </Button>
            </Box>
          </Dialog>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
