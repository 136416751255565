import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GpsNotFixed from '@mui/icons-material/GpsNotFixed';
import GpsFixed from '@mui/icons-material/GpsFixed';
import GpsOff from '@mui/icons-material/GpsOff';
import InboxIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import VoteIcon from '@mui/icons-material/HowToVote';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import { DIFFICULTY_TEXT } from '../../types';
import appStore from '../../stores/app';
import uiStore from '../../stores/ui';
import { observer } from 'mobx-react-lite';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useLocation } from 'react-router-dom';
import { createUser } from '../../backend';
import { useToastContext } from '../../context/ToastContext';
import GpsStatusIndicator from './GpsStatusIndicator';

export default observer(function Menu() {
  const [open, setOpen] = React.useState(false);
  const toggleMenu = React.useCallback(() => setOpen((x) => !x), []);
  const navigate = useNavigate();
  const location = useLocation();
  const [lastNonTripLocation, setLastNonTripLocation] =
    React.useState<string>('');
  const { showToast } = useToastContext();

  React.useMemo(() => {
    if (!location.pathname.startsWith('/trip/')) {
      setLastNonTripLocation(location.pathname);
    }
  }, [location]);

  const { page, id } = uiStore.appBar;

  const goBack = React.useCallback(() => {
    if (page === 'trip' && id) {
      if (
        lastNonTripLocation &&
        !lastNonTripLocation.startsWith('/activity') &&
        !lastNonTripLocation.startsWith('/spot')
      ) {
        navigate(lastNonTripLocation);
      } else {
        navigate('/home/list', { replace: true });
      }
    } else if (
      page == 'signIn' ||
      page == 'account' ||
      page == 'about' ||
      page == 'help' ||
      page == 'luzanky'
    ) {
      navigate('/home/list', { replace: true });
    } else if (page === 'spot' && id) {
      const entity = appStore.trips.spot[id];
      navigate(`/trip/${entity.id_trip}/spots`, { replace: true });
    } else {
      navigate(-1);
    }
  }, [navigate, page, id, lastNonTripLocation]);

  const logout = async () => {
    appStore.signOut();
    showToast(
      'Odhlášení proběhlo úspěšně, nyní můžete aplikaci používat anonymně ve výchozím stavu.',
      'success',
    );
    await createUser();
    toggleMenu();
  };

  let title: string | undefined = 'LApka';

  switch (page) {
    case 'trip':
      title = appStore.trips.collection[id || '']?.title;
      break;
    case 'spot':
      title = appStore.trips.spot[id || '']?.title;
      break;
    case 'activity':
      title = DIFFICULTY_TEXT.get(parseInt(id || '', 10));
      break;
    case 'help':
      title = 'Nápověda';
      break;
    case 'luzanky':
      title = 'SVČ Lužánky';
      break;
    case 'about':
      title = 'O LApce';
      break;
    case 'account':
      title = 'Můj účet';
      break;
    case 'username':
      title = appStore.nickname ? 'Změna přezdívky' : 'Nastavení přezdívky';
      break;
    case 'password':
      title = 'Změna hesla';
      break;
    case 'signIn':
      title = 'Přihlásit se';
      break;
    case 'signUp':
      title = 'Registrovat';
  }

  return (
    <div>
      <Drawer
        open={open}
        onClose={toggleMenu}
        sx={{ minWidth: '210px', flexShrink: 0 }}
        PaperProps={{ style: { minWidth: '210px' } }}
      >
        <List
          component="nav"
          subheader={
            <img
              alt=""
              style={{ width: '200px' }}
              src="/images/lapka_logo.png"
            />
          }
        >
          {appStore.admin && (
            <ListItem onClick={toggleMenu} component={Link} to="/admin/trips">
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          )}

          {!appStore?.email && (
            <ListItem onClick={toggleMenu} component={Link} to="/signIn">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Přihlásit se" />
            </ListItem>
          )}

          {appStore?.email && (
            <ListItem onClick={toggleMenu} component={Link} to="/account">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={appStore.email} />
            </ListItem>
          )}

          <ListItem onClick={toggleMenu} component={Link} to="/about">
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="O LApce" />
          </ListItem>

          <ListItem onClick={toggleMenu} component={Link} to="/home/list">
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Seznam cest" />
          </ListItem>

          <ListItem onClick={toggleMenu} component={Link} to="/luzanky" divider>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="SVČ Lužánky" />
          </ListItem>

          <ListItem onClick={toggleMenu} component={Link} to="/help" divider>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Nápověda" />
          </ListItem>
        </List>

        <List
          style={{
            width: '100%',
            position: 'absolute',
            textAlign: 'center',
            bottom: 0,
          }}
        >
          {appStore.email && (
            <ListItem
              onClick={logout}
              divider
              style={{ color: '#ff0000', cursor: 'pointer' }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Odhlásit" />
            </ListItem>
          )}

          <Typography sx={{ color: '#B4B5B4' }} fontSize={12}>
            Copyright © {new Date().getFullYear().toString()} www.luzanky.cz
          </Typography>
          <Typography sx={{ color: '#B4B5B4' }} fontSize={12}>
            Verze {process.env.REACT_APP_RELEASE_VERSION || 'unknown'}
          </Typography>
        </List>
      </Drawer>

      <AppBar position="fixed" style={{ backgroundColor: '#006bcc' }}>
        <Toolbar>
          {uiStore.appBar.page === 'home' && (
            <IconButton
              style={{ marginLeft: -12, marginRight: 20 }}
              color="inherit"
              aria-label="Menu"
              onClick={toggleMenu}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}

          {[
            'trip',
            'spot',
            'activity',
            'help',
            'about',
            'luzanky',
            'account',
            'username',
            'signIn',
            'signUp',
            'password',
          ].includes(uiStore.appBar.page) && (
            <IconButton
              style={{ marginLeft: -12, marginRight: 20 }}
              color="inherit"
              aria-label="Menu"
              onClick={goBack}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flexGrow: 1,
              fontSize: {
                xs: '1.1rem',
                sm: '1.5rem',
              },
            }}
          >
            {title || 'LApka'}
          </Typography>

          {(uiStore.tripLoading || uiStore.appLoading) && (
            <CircularProgress color="inherit" />
          )}
          <GpsStatusIndicator />
        </Toolbar>
      </AppBar>
    </div>
  );
});
