import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getUserToken, sendEmail, signIn } from '../backend';
import uiStore from '../stores/ui';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useToastContext } from '../context/ToastContext';
import { EMAIL_TEMPLATES } from '../components/EmailTemplates';

const theme = createTheme();

export default function SignIn() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: 'signIn',
    };

    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [openReset, setOpenReset] = useState(false);

  const [emailLog, setEmailLog] = useState('');
  const [passwordLog, setPasswordLog] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { showToast } = useToastContext();

  const [resetEmail, setResetEmail] = useState('');

  const [showWarning, setShowWarning] = React.useState(false);
  const [warning, setWarning] = React.useState('');

  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('white');

  const login = async () => {
    if (emailLog == '' || passwordLog == '') {
      setWarning('Vyplňte prosím údaje.');
      setShowWarning(true);
      return;
    }
    const response = await signIn(emailLog, passwordLog);

    if (response == 0) {
      showToast('Přihlášení proběhlo úspěšně', 'success');
      navigate('/home/list');
    } else if (response == 1) {
      setWarning('Nesprávný email nebo heslo.');
      setShowWarning(true);
    } else {
      setWarning(
        'Nejste připojení k internetu, nelze se proto přihlásit. Prosím zkontrolujte připojení. Pokud problém přetrvává, kontaktujte podporu.',
      );
      setShowWarning(true);
    }
  };

  const getToken = async () => {
    try {
      if (!resetEmail) {
        setMessageColor('red');
        setMessage('E-mail nesmí být prázdný.');
        return;
      }

      const response = await getUserToken(resetEmail);

      switch (response.result) {
        case 0: {
          const { subject, content } = EMAIL_TEMPLATES.PASSWORD_RESET(
            response.token,
          );

          await sendEmail(
            process.env.EMAIL_LOGIN || '',
            resetEmail,
            content as any,
            subject,
          );

          setMessageColor('green');
          setMessage('E-mail s instrukcemi pro reset hesla byl odeslán.');
          break;
        }

        case 1: {
          setMessageColor('red');
          setMessage('Účet se zadaným e-mailem neexistuje.');
          break;
        }

        default: {
          setMessageColor('red');
          setMessage(
            'Při resetu hesla nastala nečekaná chyba. Prosím kontaktujte podporu LApky.',
          );
          break;
        }
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setMessageColor('red');
      setMessage(
        'Při odesílání e-mailu nastala chyba. Prosím zkuste to znovu později.',
      );
    }
  };

  return (
    <Box
      sx={{
        padding: '10px',
        '& > div': {
          marginBottom: '10px',
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img alt="" style={{ width: '90%' }} src="/images/lapka_logo.png" />
            <Typography component="h1" variant="h5">
              Přihlásit se
            </Typography>
            {showWarning ? (
              <Typography sx={{ mt: 1 }} align="center" color="red">
                {warning}
              </Typography>
            ) : null}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Emailová adresa"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setEmailLog(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Heslo"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onChange={(e) => {
                  setPasswordLog(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={login}
              >
                Přihlásit
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => setOpenReset(true)}
                  >
                    Zapoměli jste heslo?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    component={RouterLink}
                    to="/signUp"
                  >
                    Nová registrace
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Dialog
            open={openReset}
            PaperProps={{ sx: { width: '80%', height: '40%' } }}
            onClose={() => setOpenReset(false)}
          >
            <IconButton
              sx={{ position: 'absolute', top: 3, right: 3 }}
              onClick={() => setOpenReset(false)}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                padding: '10px',
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                align="center"
                color={messageColor}
                variant="subtitle2"
              >
                {message}
              </Typography>
              <Typography variant="h5">Reset hesla</Typography>
              <Typography variant="subtitle2" align="center">
                Zadejte prosím e-mail s kterým jste se registrovali do aplikace
                LApka.
              </Typography>
              <TextField
                margin="normal"
                fullWidth
                required
                id="email"
                label="Emailová adresa"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setResetEmail(e.target.value);
                }}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={getToken}
              >
                Vyžádat reset hesla
              </Button>
            </Box>
          </Dialog>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
