import React, { createContext, useContext } from 'react';
import Toast from '../components/ui/Toast';
import { AlertColor } from '@mui/material';
import { useToast } from '../hooks/useToast';

interface ToastContextType {
  showToast: (message: string, severity?: AlertColor) => void;
  hideToast: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { open, message, severity, showToast, hideToast } = useToast();

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <Toast
        open={open}
        message={message}
        severity={severity}
        onClose={hideToast}
      />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
};
