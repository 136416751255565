const EARTH_RADIUS = 6371;

function toRadian(degree: number) {
  return (degree * Math.PI) / 180;
}

export type Gps = {
  lat: number;
  lng: number;
};

/**
 * Return distance
 * @param {*} origin
 * @param {*} destination
 * @return {number} in meters
 */
export function getDistance(a: Gps | null, b: Gps | null) {
  if (!a || !b) {
    return -1;
  }

  const lng1 = toRadian(a.lng);
  const lat1 = toRadian(a.lat);
  const lng2 = toRadian(b.lng);
  const lat2 = toRadian(b.lat);

  const deltaLat = lat2 - lat1;
  const deltaLng = lng2 - lng1;

  const c =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) ** 2;

  return 2 * Math.asin(Math.sqrt(c)) * EARTH_RADIUS * 1000;
}

export function optimiseImageUrl(url: string) {
  return url;
}

export function calculateTotalDistance(spotList: number[], spotsGps: Gps[]) {
  let totalDistance = 0;
  for (let i = 0; i < spotList.length; i++) {
    if (spotList[i] === 1) {
      // Check if spot is available
      for (let j = i + 1; j < spotList.length; j++) {
        if (spotList[j] === 1) {
          // Check if spot is available
          totalDistance += getDistance(spotsGps[i], spotsGps[j]);
          break;
        }
      }
    }
  }
  return totalDistance;
}

// Inlined https://raw.githubusercontent.com/veltman/xyz-affair/master/index.js
// and added TypeScript types to the original code

const R = 6378137;
const sphericalScale = 0.5 / (Math.PI * R);

type Bounds = [[number, number], [number, number]];
type Tile = { x: number; y: number; z: number };

export function xyz(bounds: Bounds, minZoom: number, maxZoom?: number): Tile[] {
  let min, max: number;

  if (!maxZoom) {
    max = min = minZoom;
  } else if (maxZoom < minZoom) {
    min = maxZoom;
    max = minZoom;
  } else {
    min = minZoom;
    max = maxZoom;
  }

  const tiles = [];
  for (let z = min; z <= max; z++) {
    tiles.push(...xyzInner(bounds, z));
  }
  return tiles;
}

/* Adapted from: https://gist.github.com/mourner/8825883 */
function xyzInner(bounds: Bounds, zoom: number): Tile[] {
  //north,west
  const min = project(bounds[1][1], bounds[0][0], zoom);
  //south,east
  const max = project(bounds[0][1], bounds[1][0], zoom);

  const tiles = [];
  for (let x = min.x; x <= max.x; x++) {
    for (let y = min.y; y <= max.y; y++) {
      tiles.push({ x, y, z: zoom });
    }
  }
  return tiles;
}

/* Adapts a group of functions from Leaflet.js to work headlessly
   https://github.com/Leaflet/Leaflet

   Combines/modifies the following methods:
   L.Transformation._transform (src/geometry/Transformation.js)
   L.CRS.scale (src/geo/crs/CRS.js)
   L.CRS.latLngToPoint (src/geo/crs/CRS.js)
   L.Projection.SphericalMercator.project (src/geo/projection/Projection.SphericalMercator.js)
*/
function project(lat: number, lng: number, zoom: number) {
  const d = Math.PI / 180;
  const max = 1 - 1e-15;
  const sin = Math.max(Math.min(Math.sin(lat * d), max), -max);
  const scale = 256 * Math.pow(2, zoom);

  const point = {
    x: R * lng * d,
    y: (R * Math.log((1 + sin) / (1 - sin))) / 2,
  };
  return {
    x: tiled(scale * (sphericalScale * point.x + 0.5)),
    y: tiled(scale * (-sphericalScale * point.y + 0.5)),
  };
}

function tiled(num: number) {
  return Math.floor(num / 256);
}

export const getRatingColor = (rating: string | number) => {
  rating = parseFloat(rating as string);
  if (rating >= 4.5) return '#2e7d32';
  if (rating >= 4) return '#1b5e20';
  if (rating >= 3.5) return '#ed6c02';
  if (rating >= 3) return '#d97706';
  if (rating >= 2) return '#d32f2f';
  return '#c62828';
};
