import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fade,
  Divider,
  Container,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import {
  Search as SearchIcon,
  PersonAdd as PersonAddIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import useDebounce from '../../hooks/useDebounce';
import { searchUsers, updateAdminStatus } from '../../backend';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import { useToastContext } from '../../context/ToastContext';
import { useSearchParams, useLocation } from 'react-router-dom';

interface User {
  id: number;
  email: string;
  name: string;
  isAdmin: boolean;
}

const AdminManagement = () => {
  const theme = useTheme();

  const { showToast } = useToastContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [dialogOpenAddAdmin, setDialogOpenAddAdmin] = useState(false);
  const [dialogOpenRemoveAdmin, setDialogOpenRemoveAdmin] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);

  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  React.useEffect(() => {
    const performSearch = async () => {
      if (debouncedSearchQuery.trim().length < 2) {
        setUsers([]);
        return;
      }

      setIsSearching(true);
      setError(null);

      try {
        const response = await searchUsers(debouncedSearchQuery);
        if (response.success) {
          setUsers(response.users);
        } else {
          setError(response.message || 'Došlo k chybě při vyhledávání');
        }
      } catch (err: any) {
        setError(err.response.data.message || 'Došlo k chybě při vyhledávání');
        console.error('Search error:', err);
      } finally {
        setIsSearching(false);
      }
    };

    performSearch();
  }, [debouncedSearchQuery]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setIsSearching(true);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setUsers([]);
  };

  const handleAddAdmin = (user: User) => {
    setSelectedUser(user);
    setDialogOpenAddAdmin(true);
  };

  const handleRemoveAdmin = (user: User) => {
    setSelectedUser(user);
    setDialogOpenRemoveAdmin(true);
  };

  const handleOnCloseAddAdmin = () => {
    setDialogOpenAddAdmin(false);
    setError(null);
    setSelectedUser(null);
  };

  const handleOnCloseRemoveAdmin = () => {
    setDialogOpenRemoveAdmin(false);
    setError(null);
    setSelectedUser(null);
  };
  console.log(error);
  const confirmRemoveAdmin = async (selectedUser: User) => {
    try {
      const response = await updateAdminStatus(selectedUser.id, false);
      if (response.success) {
        setUsers((currentUsers) =>
          currentUsers.map((u) =>
            u.id === selectedUser.id ? { ...u, isAdmin: false } : u,
          ),
        );
        showToast('Uživatel byl úspěšně odebrán z administrátorů.', 'success');
        setDialogOpenRemoveAdmin(false);
        setSelectedUser(null);
        setError(null);
      } else {
        setError(
          response.message || 'Nastala chyba při odebírání administrátora',
        );
      }
    } catch (err: any) {
      setError(
        err.response.data.message || 'Došlo k chybě při odebírání admina',
      );
      console.error('Search error:', err);
    }
  };

  const confirmMakeAdmin = async (selectedUser: User) => {
    try {
      const response = await updateAdminStatus(selectedUser.id, true);
      console.log(response);
      if (response.success && response.user) {
        setUsers((currentUsers) =>
          currentUsers.map((user) =>
            user.id === selectedUser.id ? { ...user, isAdmin: true } : user,
          ),
        );
        showToast('Uživatel byl úspěšně přidán jako administrátor.', 'success');
        setDialogOpenAddAdmin(false);
        setSelectedUser(null);
      } else {
        setError(
          response.message || 'Nastala chyba při přidávání administrátora',
        );
      }
    } catch (err: any) {
      console.error('Add admin error:', err);
      setError(
        err.response.data.message || 'Došlo k chybě při přidávání admina',
      );
      console.error('Add admin error:', err);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: { xs: 8, sm: 12 },
        pb: { xs: 6, sm: 8 },
        px: { xs: 2, sm: 3 },
        display: 'flex',
        width: {
          sm: '100%',
          md: 'calc(100% - 240px)',
        },
        alignItems: 'flex-start',
        margin: 'auto',
        justifyContent: 'center',
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="md">
        <Fade in timeout={500}>
          <Card
            elevation={2}
            sx={{
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: 'background.paper',
            }}
          >
            <CardContent sx={{ p: { xs: 3, sm: 4, md: 5 } }}>
              <Box sx={{ mb: 6, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                  Správa administrátorů
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  Vyhledávejte a přidávejte nové administrátory
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: 2, mb: 6 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Vyhledat uživatele..."
                  value={searchQuery}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{
                          mr: 1,
                          color: isSearching
                            ? 'primary.main'
                            : 'text.secondary',
                          transition: 'color 0.2s',
                        }}
                      />
                    ),
                    endAdornment: (
                      <>
                        {searchQuery && (
                          <IconButton
                            size="small"
                            onClick={handleClearSearch}
                            sx={{ mr: 0.5 }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                  }}
                />
              </Box>

              <>
                {isSearching && debouncedSearchQuery ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size={30} />
                  </Box>
                ) : null}

                <List sx={{ mb: 4 }}>
                  {users.map((user) => (
                    <React.Fragment key={user.id}>
                      <ListItem
                        sx={{
                          py: 2,
                          px: { xs: 1, sm: 2 },
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                        }}
                      >
                        <ListItemText
                          primary={user.name}
                          secondary={user.email}
                          primaryTypographyProps={{
                            variant: 'subtitle1',
                            fontWeight: 'normal',
                            mb: 0.5,
                          }}
                          secondaryTypographyProps={{
                            sx: { color: 'text.secondary' },
                          }}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            title={
                              user.isAdmin ? 'Odebrat admina' : 'Přidat admina'
                            }
                          >
                            {!user.isAdmin ? (
                              <Button
                                variant="outlined"
                                startIcon={<PersonAddIcon />}
                                onClick={() => handleAddAdmin(user)}
                                sx={{
                                  borderRadius: 2,
                                  px: 2,
                                }}
                              >
                                {isMobile ? null : 'Přidat admina'}
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleRemoveAdmin(user)}
                                sx={{
                                  borderRadius: 2,
                                  px: 2,
                                }}
                              >
                                {isMobile ? null : 'Odebrat admina'}
                              </Button>
                            )}
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}

                  {debouncedSearchQuery &&
                    !isSearching &&
                    users.length === 0 && (
                      <Typography
                        color="text.secondary"
                        sx={{ textAlign: 'center', py: 4 }}
                      >
                        Žádní uživatelé nenalezeni
                      </Typography>
                    )}
                </List>
              </>
            </CardContent>
          </Card>
        </Fade>
      </Container>

      <ConfirmDialog
        open={dialogOpenAddAdmin}
        onClose={handleOnCloseAddAdmin}
        title="Přidat administrátora"
        content={`Opravdu chcete přidat uživatele ${selectedUser?.name} jako administrátora?`}
        error={error || ''}
        onConfirm={() => confirmMakeAdmin(selectedUser!)}
      />

      <ConfirmDialog
        open={dialogOpenRemoveAdmin}
        onClose={handleOnCloseRemoveAdmin}
        title="Odebrat administrátora"
        content={`Opravdu chcete odebrat uživateli ${selectedUser?.name} administrátorská práva?`}
        error={error || ''}
        onConfirm={() => confirmRemoveAdmin(selectedUser!)}
      />
    </Box>
  );
};

export default AdminManagement;
