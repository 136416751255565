import { AlertColor } from '@mui/material';
import React from 'react';
export const useToast = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState<AlertColor>('success');

  const showToast = (
    newMessage: string,
    newSeverity: AlertColor = 'success',
  ) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOpen(true);
  };

  const hideToast = () => {
    setOpen(false);
  };

  return {
    open,
    message,
    severity,
    showToast,
    hideToast,
  };
};
