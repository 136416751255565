import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import uiStore from '../../stores/ui';
import appStore from '../../stores/app';
import { getDistance } from '../../utils/utils';
import { DISTANCE_OFFSET } from '../../constants';
import Popup from '../ui/Popup';
import NewStopAvailablePopup from '../../popups/NewStopAvailablePopup';
import TripFinishedPopup from '../../popups/TripFinishedPopup';
import { useToastContext } from '../../context/ToastContext';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface SpotLocation {
  id: number;
  name: string;
  gps: {
    lat: number;
    lng: number;
  };
}

interface SimulatedSpot extends SpotLocation {
  description?: string;
  serial_number: number;
  visited: boolean;
}

const GeoListenerWithSimulator = observer(({ tripId }: { tripId: number }) => {
  const { showToast } = useToastContext();
  const [isSimulated, setIsSimulated] = useState(false);
  const [watch, setWatch] =
    useState<ReturnType<typeof navigator.geolocation.watchPosition>>();

  const tripSpots = React.useMemo((): SimulatedSpot[] => {
    if (!tripId || !appStore.trips.collection[tripId]) return [];

    return appStore.trips.collection[tripId].spots.map((spotId) => {
      const spot = appStore.trips.spot[spotId];
      return {
        id: spotId,
        name: spot.title,
        description: spot.description,
        serial_number: spot.serial_number,
        gps: spot.gps,
        visited: appStore.isSpotVisited(spotId),
      };
    });
  }, [
    tripId,
    appStore.trips.collection[tripId],
    appStore.trips.spot,
    appStore.trips.progress,
  ]);

  const handlePositionUpdate = ({
    lat,
    lng,
    accuracy,
  }: {
    lat: number;
    lng: number;
    accuracy: number;
  }) => {
    uiStore.gpsAvailable = true;
    uiStore.currentUserPosition = { lat, lng, accuracy };

    if (accuracy > 100) return;

    const candidateSpots = appStore.trips.collection[tripId].spots.flatMap(
      (spotId) => {
        if (
          !!uiStore.ignoredReachedSpots[spotId] ||
          appStore.trips.progress[spotId]?.visited
        ) {
          return [];
        }
        const { id, gps } = appStore.trips.spot[spotId];
        const distance = getDistance(gps, { lat, lng });
        if (distance >= accuracy + DISTANCE_OFFSET) {
          return [];
        }
        return [{ id, distance, accuracy }];
      },
    );

    candidateSpots.sort((a, b) => b.distance - a.distance);
    const reachedSpot = candidateSpots[0];

    if (reachedSpot) {
      uiStore.reachedSpot = reachedSpot.id;
      appStore.spotVisited(reachedSpot.id);
      showToast('Gratulujeme, dorazili jste na nové místo!', 'success');
      if (
        appStore.trips.collection[tripId].spots.every((spotId) =>
          appStore.isSpotVisited(spotId),
        )
      ) {
        uiStore.finishedTrip = tripId;
        showToast('Gratulujeme, dokončili jste trasu!', 'success');
      }
    }
  };

  const startRealTracking = () => {
    const watchId = navigator.geolocation.watchPosition(
      ({ coords: { latitude: lat, longitude: lng, accuracy } }) => {
        handlePositionUpdate({ lat, lng, accuracy });
      },
      () => {
        uiStore.gpsAvailable = false;
      },
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      },
    );
    setWatch(watchId);
  };

  const GPSSimulator = () => {
    const [currentPosition, setCurrentPosition] = useState(
      tripSpots.length > 0
        ? { lat: tripSpots[0].gps.lat, lng: tripSpots[0].gps.lng, accuracy: 50 }
        : { lat: 0, lng: 0, accuracy: 50 },
    );

    const simulateMovement = (targetSpot: {
      id: number;
      name: string;
      gps: { lat: number; lng: number };
    }) => {
      setCurrentPosition((prev) => {
        const newPos = {
          lat: targetSpot.gps.lat,
          lng: targetSpot.gps.lng,
          accuracy: 50,
        };
        handlePositionUpdate(newPos);
        return newPos;
      });
    };

    if (!tripId || !appStore.trips.collection[tripId]) {
      return (
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <Typography color="text.secondary">
              No trip data available
            </Typography>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card variant="outlined" sx={{ mt: 2 }}>
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
          >
            <LocationOnIcon sx={{ mr: 1 }} />
            GPS Simulátor lokací
          </Typography>
          <Grid container spacing={2}>
            {tripSpots.map((spot) => {
              const spotLocation: SpotLocation = {
                id: spot.id,
                name: spot.name,
                gps: spot.gps,
              };

              return (
                <Grid item xs={12} sm={6} key={spot.id}>
                  <Tooltip
                    title={
                      spot.description?.replace(/<[^>]*>/g, '') ||
                      'No description'
                    }
                  >
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => simulateMovement(spotLocation)}
                      startIcon={
                        spot.visited ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )
                      }
                      sx={{
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        backgroundColor: spot.visited
                          ? 'action.hover'
                          : 'inherit',
                      }}
                    >
                      {spot.name || `Spot ${spot.serial_number}`}
                    </Button>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
          <Paper
            elevation={0}
            variant="outlined"
            sx={{
              mt: 2,
              p: 1,
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Aktuální pozice: {currentPosition.lat.toFixed(6)},{' '}
              {currentPosition.lng.toFixed(6)}
            </Typography>
          </Paper>
        </CardContent>
      </Card>
    );
  };

  React.useEffect(() => {
    if (!appStore.activeTrip || appStore.activeTrip !== tripId) return;

    uiStore.reachedSpot = null;
    uiStore.finishedTrip = null;

    if (!isSimulated) {
      startRealTracking();
    }

    return () => {
      if (watch) {
        navigator.geolocation.clearWatch(watch);
      }
    };
  }, [tripId, appStore.activeTrip, isSimulated]);

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={isSimulated}
            onChange={(e) => setIsSimulated(e.target.checked)}
            color="primary"
          />
        }
        style={{ margin: 0 }}
        label="Použít GPS simulaci"
      />

      {isSimulated && <GPSSimulator />}

      <Popup
        open={
          !!uiStore.reachedSpot &&
          !uiStore.finishedTrip &&
          !uiStore.ignoredReachedSpots[uiStore.reachedSpot]
        }
        data={{ onClose: uiStore.closeReachedSpotPopup }}
        content={NewStopAvailablePopup}
      />
      <Popup
        open={
          !!uiStore.reachedSpot &&
          !!uiStore.finishedTrip &&
          !uiStore.ignoredReachedSpots[uiStore.reachedSpot]
        }
        data={{ onClose: uiStore.closeReachedSpotPopup }}
        content={TripFinishedPopup}
      />
    </div>
  );
});

export default GeoListenerWithSimulator;
