import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from '@mui/material';
import uiStore from '../stores/ui';
import appStore from '../stores/app';
import { CachedImage } from '../components/CachedImage';
import { observer } from 'mobx-react-lite';

export default observer(function Popup({ onClose }: { onClose: () => void }) {
  if (!uiStore.reachedSpot) return null;

  const spot = appStore.trips.spot[uiStore.reachedSpot];

  return (
    <Card>
      <CardActionArea>
        <CachedImage
          image={spot.photo.photo_url}
          cacheName={`trip-${spot.id_trip}`}
        >
          {(image) => (
            <CardMedia
              style={{
                height: 240,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              image={image}
              title={spot.title}
              component={Link}
              to={`/spot/${uiStore.reachedSpot}`}
            />
          )}
        </CachedImage>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {spot.title}
          </Typography>
          <Typography component="p" gutterBottom>
            Gratulujeme, jste v blízkosti. Nyní můžete přejít na kartu tohoto
            místa a zůčasnit se aktivity.
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions
        sx={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          flexDirection: 'column',
          '& > button, a': {
            margin: '4px',
          },
        }}
      >
        <Button
          component={Link}
          to={`/spot/${uiStore.reachedSpot}`}
          size="small"
          variant="contained"
          color="primary"
        >
          Přejít na detail místa
        </Button>
        <Button size="small" variant="outlined" onClick={onClose}>
          Zavřít
        </Button>
      </CardActions>
    </Card>
  );
});
