import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Fab } from '@mui/material';
import * as React from 'react';
import appStore from '../../stores/app';
import { observer } from 'mobx-react-lite';
import uiStore from '../../stores/ui';

export default observer(function FilterButton({ setOpen }: { setOpen: any }) {
  return (
    <div style={{ padding: '10px' }}>
      <Fab
        sx={
          appStore.activeTrip != null && !uiStore.scrolledBottom
            ? { position: 'fixed', bottom: 235, right: 16 }
            : { position: 'fixed', bottom: 16, right: 16 }
        }
        style={{ backgroundColor: '#006bcc' }}
        aria-label="filter"
        onClick={() => setOpen(true)}
      >
        <FilterAltIcon sx={{ color: 'white' }} />
      </Fab>
    </div>
  );
});
