import React from 'react';
import { Snackbar, Alert, AlertColor, SnackbarOrigin } from '@mui/material';

interface ToastProps {
  open: boolean;
  message: string;
  severity?: AlertColor;
  autoHideDuration?: number;
  onClose: () => void;
  position?: SnackbarOrigin;
}

const Toast: React.FC<ToastProps> = ({
  open,
  message,
  severity = 'success',
  autoHideDuration = 4000,
  onClose,
  position = {
    vertical: 'top',
    horizontal: 'center',
  },
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={position}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{
          width: '100%',
          '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
