import { getBase64Image } from '../utils/base64';

interface EmailTemplateProps {
  userName?: string;
  mainContent: React.ReactNode;
  callToAction?: {
    text: string;
    url: string;
  };
  hideGreeting?: boolean;
}

export const createEmailTemplate = ({
  userName = '',
  mainContent,
  callToAction,
  hideGreeting = false,
}: EmailTemplateProps): string => {
  const logoUrl = 'https://lapka.luzanky.cz/images/lapka_logo.png';
  return `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="color-scheme" content="light">
        <meta name="supported-color-schemes" content="light">
      </head>
      <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; line-height: 1.6; color: #333333;">
        <table width="100%" cellpadding="0" cellspacing="0" style="max-width: 600px; margin: 0 auto; padding: 20px;">
          <tr>
            <td style="text-align: center; padding: 10px 0;">
              <img src="${logoUrl}" alt="LApka Logo" style="max-width: 150px; height: auto;">
            </td>
          </tr>
          <tr>
            <td style="padding: 20px; background-color: #ffffff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
              ${hideGreeting ? '' : '<p>Dobrý den,</p>'}
              
              ${mainContent}
              
              ${
                callToAction
                  ? `
                <div style="text-align: center; margin: 30px 0;">
                  <a href="${callToAction.url}" 
                     style="background-color: #006bcc; 
                            color: white; 
                            padding: 12px 25px; 
                            text-decoration: none; 
                            border-radius: 5px; 
                            font-weight: bold;
                            display: inline-block;">
                    ${callToAction.text}
                  </a>
                </div>
              `
                  : ''
              }
              
              <div style="margin-top: 30px; padding-top: 20px; border-top: 1px solid #eee;">
                <p style="margin: 0;">S přátelským pozdravem,</p>
                <p style="margin: 5px 0; font-weight: bold;">LApka Team</p>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center; padding: 20px; font-size: 12px; color: #666;">
              <p style="margin: 5px 0;">© ${new Date().getFullYear()} LApka. Všechna práva vyhrazena.</p>
              <p style="margin: 5px 0;">
                Kontakt: <a href="mailto:lapka@luzanky.cz" style="color: #4CAF50;">lapka@luzanky.cz</a>
              </p>
              <p style="margin: 5px 0;">
                Web: <a href="${
                  window.location.origin
                }" style="color: #4CAF50;">${window.location.origin}</a>
              </p>
            </td>
          </tr>
        </table>
      </body>
      </html>
    `;
};

export const EMAIL_TEMPLATES = {
  REGISTRATION_VERIFICATION: (token: string) => {
    const content = createEmailTemplate({
      mainContent: `
        <p>Děkujeme za registraci v aplikaci LApka!</p>
        <p>Pro aktivaci vašeho účtu a přístup ke všem funkcím aplikace je potřeba potvrdit vaši emailovou adresu.</p>
        <p>Pokud jste se neregistrovali do aplikace LApka, můžete tento email ignorovat.</p>
      `,
      callToAction: {
        text: 'Potvrdit emailovou adresu',
        url: `${window.location.origin}/verify/${token}`,
      },
    });

    return {
      subject: 'LApka - Dokončení registrace',
      content,
    };
  },

  PASSWORD_RESET: (token: string) => {
    const resetUrl = `${window.location.origin}/resetPassword/${token}`;
    const content = createEmailTemplate({
      mainContent: `
        <p>Obdrželi jsme žádost o reset hesla k vašemu účtu v aplikaci LApka.</p>
        <p>Pro nastavení nového hesla klikněte na tlačítko níže. Z bezpečnostních důvodů je tento odkaz platný pouze po omezenou dobu.</p>
        <p><strong>Poznámka:</strong> 
          Pokud jste o resetování hesla nežádali, můžete tento email ignorovat. Vaše heslo zůstane nezměněno.
        </p>
      `,
      callToAction: {
        text: 'Nastavit nové heslo',
        url: resetUrl,
      },
    });
    return {
      subject: 'LApka - Reset hesla',
      content,
    };
  },
  CONTACT_AUTHOR: ({
    senderEmail,
    tripTitle,
    message,
    tripId,
  }: {
    senderEmail: string;
    tripTitle: string;
    message: string;
    tripId: number;
  }) => {
    const content = createEmailTemplate({
      hideGreeting: true,
      mainContent: `
        <div style="background-color: #f5f5f5; padding: 15px; border-radius: 5px; margin: 15px 0;">
          <p style="margin-top: 0;"><strong>Od:</strong> ${senderEmail}</p>
          <p><strong>K trase:</strong> ${tripTitle}</p>
          <p><strong>Zpráva:</strong></p>
          <div style="background-color: white; padding: 15px; border-radius: 5px; margin-top: 10px;">
            ${message.replace(/\n/g, '<br/>')}
          </div>
        </div>
      `,
      callToAction: {
        text: 'Zobrazit trasu v aplikaci',
        url: `${window.location.origin}/trip/${tripId}/description`,
      },
    });

    return {
      subject: `LApka - Zpráva od uživatele k trase "${tripTitle}"`,
      content,
    };
  },
};
