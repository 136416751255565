import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

const TripInfo: React.FC<{
  icon: React.ReactElement;
  label: string;
  tooltip: string;
}> = ({ icon, label, tooltip }) => (
  <Tooltip title={tooltip} enterTouchDelay={1} placement="top" arrow>
    <IconButton aria-label={tooltip} size="large" disableRipple>
      {React.cloneElement(icon, { fontSize: 'small' })}
      <span style={{ fontSize: 17 }}> &nbsp;{label}</span>
    </IconButton>
  </Tooltip>
);

export default TripInfo;
