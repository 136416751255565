import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import appStore from '../stores/app';
import uiStore from '../stores/ui';
import TripStat from '../components/trip/TripStat';
import { Delete } from '@mui/icons-material';
import { removeUser } from '../backend';
import { useToastContext } from '../context/ToastContext';

export default function Account() {
  const [openDialog, setOpenDialog] = React.useState(false);
  React.useEffect(() => {
    uiStore.appBar = {
      page: 'account',
    };

    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const tripsWithProgress: number[] = [];

  if (appStore.trips.list) {
    appStore.trips.list.forEach((tripId) => {
      const trip = appStore.trips.collection[tripId];
      const hasProgress = trip.spots.some(
        (spotId) => appStore.trips.progress[spotId]?.visited,
      );

      if (hasProgress) {
        tripsWithProgress.push(tripId);
      }
    });
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemoveUser = async (email: string, token: string) => {
    if (!email || !token) {
      console.error('Email or token missing');
      showToast(
        'Nepodařilo se smazat účet, kontaktujte prosím podporu',
        'error',
      );
      return;
    }

    const result = await removeUser(email, token);

    if (result?.success) {
      setOpenDialog(false);
      showToast('Účet byl úspěšně smazán', 'success');
      navigate('/home/list');
    } else {
      console.error('Failed to remove user');
      showToast(
        'Nepodařilo se smazat účet, kontaktujte prosím podporu',
        'error',
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          padding: '10px',
          '& > div': {
            marginBottom: '10px',
          },
        }}
      >
        <Card>
          <CardContent>
            <h2>Osobní údaje</h2>

            <Grid
              container
              sx={{
                pt: 1,
                borderBottom: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              color="#006bcc"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <EmailIcon sx={{ fontSize: '1.2rem' }} />
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Email
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography
                  component="span"
                  color="#A3A3AF"
                  sx={{
                    fontSize: 18,
                    fontWeight: 400,
                  }}
                >
                  {appStore.email}
                </Typography>
              </Stack>
            </Grid>

            <Grid
              container
              sx={{
                pt: 3,
                borderBottom: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              color="#006bcc"
              component={Link}
              to="/username"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <BadgeIcon sx={{ fontSize: '1.2rem' }} />
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Přezdívka
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography
                  component="span"
                  color="#A3A3AF"
                  sx={{
                    fontSize: 18,
                    fontWeight: 400,
                  }}
                >
                  {appStore.nickname}
                </Typography>
                <ArrowForwardIosIcon sx={{ fontSize: '1.2rem' }} />
              </Stack>
            </Grid>

            <Grid
              container
              sx={{
                pt: 3,
                borderBottom: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              color="#006bcc"
              component={Link}
              to="/password"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <KeyIcon sx={{ fontSize: '1.2rem' }} />
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Heslo
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ArrowForwardIosIcon sx={{ fontSize: '1.2rem' }} />
              </Stack>
            </Grid>
            <Grid
              container
              sx={{
                pt: 3,
                borderBottom: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              color="#006bcc"
              component={Link}
              onClick={handleOpenDialog}
              to="/account"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Delete sx={{ fontSize: '1.2rem' }} />
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Smazat účet
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ArrowForwardIosIcon sx={{ fontSize: '1.2rem' }} />
              </Stack>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <h2>Moje cesty</h2>
            {!appStore.trips.list ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={100} />
              </div>
            ) : (
              <div>
                {tripsWithProgress.map((tripId) => (
                  <TripStat tripId={tripId} />
                ))}
                {tripsWithProgress.length == 0 && (
                  <Typography
                    align="center"
                    sx={{ fontSize: 15, color: '#747474' }}
                  >
                    Vypadá to, že jste zatím nezačali žádnou trasu. Jakmile
                    vyrazíte na cestu, zde se objeví vaše statistiky.
                    Dobrodružství na vás už čeká!
                  </Typography>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Smazat účet'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opravdu chcete smazat svůj účet? Tímto krokem smažete všechny vaše
            trasy a nebudete moci obnovit svůj účet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            Zrušit
          </Button>
          <Button
            onClick={() => {
              handleRemoveUser(appStore.email ?? '', appStore.token ?? '');
            }}
            color="error"
            variant="outlined"
            autoFocus
          >
            Smazat účet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
