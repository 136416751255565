import React, { useState } from 'react';
import { Box, Button, CircularProgress, styled } from '@mui/material';
import { UploadFile } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: theme.palette.grey[50],
  '&:hover': {
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

type ImageUploadProps = {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFile: string;
  labelText: string;
  onUpload: () => void;
  isUploading: boolean;
};

const ImageUpload = ({
  onFileChange,
  selectedFile,
  labelText,
  isUploading,
  onUpload,
}: ImageUploadProps) => {
  const [isFileSelected, setIsFileSelected] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setIsFileSelected(true);
    }
    onFileChange(event);
  };

  const handleUpload = () => {
    onUpload();
  };

  return (
    <>
      <label
        style={{
          display: 'block',
          marginBottom: '8px',
          fontWeight: 500,
        }}
      >
        {labelText}
      </label>
      <StyledUploadBox>
        <Button
          component="label"
          variant="contained"
          sx={{ mb: 2, mr: 2 }}
          color={'primary'}
        >
          Vybrat soubor
          <VisuallyHiddenInput
            type="file"
            accept=".jpeg,.jpg,.png"
            onChange={handleFileChange}
          />
        </Button>

        {selectedFile && (
          <>
            <Box sx={{ mt: 2, wordBreak: 'break-all' }}>
              Vybrán soubor:
              <strong
                style={{
                  marginLeft: '8px',
                  color: '#000',
                }}
              >
                {selectedFile}
              </strong>
            </Box>
          </>
        )}
        {isFileSelected && (
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            color="primary"
            startIcon={<UploadFile />}
            onClick={handleUpload}
            disabled={!isFileSelected}
          >
            {isUploading ? (
              <>
                Nahrávání...
                <CircularProgress size={24} />
              </>
            ) : (
              'Nahrát'
            )}
          </Button>
        )}
      </StyledUploadBox>
    </>
  );
};

export default ImageUpload;
