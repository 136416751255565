import * as React from 'react';
import {
  CardHeader,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ChildIcon from '@mui/icons-material/ChildFriendly';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import GetAppIcon from '@mui/icons-material/GetApp';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import appStore from '../../stores/app';
import { CachedImage } from './../CachedImage';
import { observer } from 'mobx-react-lite';
import { getRatingColor } from '../../utils/utils';
import TripInfo from './TripInfo';
import ActionButton from '../ui/ActionButton';
import { RatingDialog } from '../ui/RatingDialog';
import { setRating } from '../../backend';
import { removeTripFromCache, cacheTrip } from '../../storage';
import uiStore from '../../stores/ui';

export const TripCard = observer(function TripCard({
  tripId,
}: {
  tripId: number;
}) {
  const trip = appStore.trips.collection[tripId];

  const averageRating = appStore.getTripRatingAverage(tripId);
  const ratingCount = trip.ratings.length;

  const isLocked = trip.password && !appStore.trips.unlocked[tripId];
  const isVisited = appStore.getTripScoreAvailableForVote(tripId);
  const cached = appStore.trips.cached[tripId];

  const [isRatingOpen, setIsRatingOpen] = React.useState(false);
  const [ratingValue, setRatingValue] = React.useState<number | null>(null);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleRatingClose = () => {
    setIsRatingOpen(false);
    setShowSuccess(false);
    setRatingValue(null);
  };

  const handleRatingSubmit = (value: number | null) => {
    if (value && appStore.user) {
      setRating(value, tripId, appStore.user);
      setShowSuccess(true);
      setTimeout(handleRatingClose, 2000);
    }
  };

  return (
    <Card
      sx={{
        marginBottom: '20px',
        opacity: isLocked ? 0.7 : 1,
        backgroundColor: isLocked ? '#ccc' : undefined,
      }}
    >
      <CardActionArea component={Link} to={`/trip/${tripId}/description`}>
        {isLocked && (
          <LockIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'white',
            }}
          />
        )}

        {isVisited && (
          <DoneIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'white',
            }}
          />
        )}

        <CachedImage
          image={trip.profile_photo.file_url}
          cacheName={`trip-${tripId}`}
        >
          {(image) => (
            <CardMedia
              style={{ height: 140 }}
              image={image}
              title={trip.profile_photo.file_name}
            />
          )}
        </CachedImage>

        <CardHeader
          title={trip.title.toUpperCase()}
          subheader={trip.short_description}
          action={
            <Tooltip
              placement="top"
              title={cached ? 'Staženo offline' : 'Stáhnout offline'}
              enterTouchDelay={1}
            >
              <>
                {cached ? (
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeTripFromCache(tripId);
                    }}
                  >
                    <OfflinePinIcon />
                  </IconButton>
                ) : !uiStore.hasConnectionIssue() ? (
                  <IconButton
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      uiStore.downloadCachePendingMap[tripId] = true;
                      await cacheTrip(tripId);
                      uiStore.downloadCachePendingMap[tripId] = false;
                    }}
                  >
                    {uiStore.downloadCachePendingMap[tripId] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GetAppIcon />
                    )}
                  </IconButton>
                ) : null}
              </>
            </Tooltip>
          }
        />
      </CardActionArea>

      <CardContent sx={{ display: 'flex', padding: 0, flexWrap: 'wrap' }}>
        {ratingCount >= 5 && (
          <ActionButton
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {ratingCount >= 5 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: getRatingColor(averageRating),
                        transition: 'color 0.3s ease',
                      }}
                    >
                      <StarIcon sx={{ color: 'inherit' }} />
                      <Typography
                        sx={{
                          color: 'inherit',
                          fontSize: '1.2rem',
                        }}
                      >
                        {averageRating}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            }
            onClick={() => setIsRatingOpen(true)}
          />
        )}
        <TripInfo
          icon={<AccessTimeIcon />}
          label={`${trip.duration} hod.`}
          tooltip="Časový odhad náročnosti cesty"
        />
        <TripInfo
          icon={<WalkIcon />}
          label={`${trip.length.toFixed(1)} km`}
          tooltip="Přibližná délka celé cesty"
        />

        {trip.baby_carriage ? (
          <TripInfo
            icon={<ChildIcon />}
            label=""
            tooltip="Vhodné pro kočárky"
          />
        ) : (
          ''
        )}

        {trip.bicycle ? (
          <TripInfo
            icon={<DirectionsBikeIcon />}
            label=""
            tooltip="Vhodné pro jízdu na kole"
          />
        ) : (
          ''
        )}
      </CardContent>
      <RatingDialog
        open={isRatingOpen}
        onClose={handleRatingClose}
        onSubmit={handleRatingSubmit}
        onChange={setRatingValue}
        value={ratingValue}
        showSuccess={showSuccess}
      />
    </Card>
  );
});
