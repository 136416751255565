import * as React from 'react';
import { Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import appStore from '../../stores/app';
import { getDistance } from '../../utils/utils';
import { SpotCard } from '../../components/SpotCard';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

export default observer(function TripSpots() {
  const { id } = useParams();
  const tripId = parseInt(id || '', 10);

  const spots = appStore.trips.collection[tripId].spots.map(
    (id) => appStore.trips.spot[id],
  );
  const spotsAvailable =
    appStore.trips.collection[tripId].spotsAlwaysShown ||
    appStore.trips.showSpots[tripId]
      ? spots
      : spots.filter((spot, i) => {
          if (
            i == 0 ||
            appStore.isSpotVisited(spot.id) ||
            (!appStore.isSpotVisited(spot.id) &&
              appStore.isSpotVisited(spots[i - 1].id))
          ) {
            return true;
          }
        });

  if (!spotsAvailable.length) {
    return <div>čekám na připojení k internetu...</div>;
  }

  return (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      {spotsAvailable.map(({ id, gps }, i) => {
        const nextSpot =
          i < spotsAvailable.length - 1 && spotsAvailable[i + 1].gps;
        return (
          <React.Fragment key={id}>
            <SpotCard id={id} />
            {!!nextSpot && (
              <div style={{ margin: '15px' }}>
                <Chip
                  label={`${Math.round(getDistance(gps, nextSpot))}m`}
                  variant="outlined"
                />
                <br />
                <ExpandMoreIcon />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
});
