import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  Button,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Link, useLocation } from 'react-router-dom';
import { CachedImage } from '../CachedImage';
import appStore from '../../stores/app';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import uiStore from '../../stores/ui';
import TripInfo from './TripInfo';

export default observer(function TripActive() {
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();

  const score =
    appStore.activeTrip != null
      ? appStore.getTripScore(appStore.activeTrip)
      : { list: [], percentage: 0 };

  const stopsVisitedAlready = score.list.filter((a) => a).length;
  const totalStops = score.list.length == 0 ? 1 : score.list.length;
  const progressPercentage = Math.round(
    (stopsVisitedAlready / totalStops) * 100,
  );

  useEffect(() => {
    window.addEventListener('scroll', uiStore.handleScroll);
    return () => {
      window.removeEventListener('scroll', uiStore.handleScroll);
    };
  }, []);

  const handleOpenDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeactiveTrip = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleCloseDialog();
    appStore.deactivateTrip();
  };

  return (
    <div>
      {appStore.activeTrip != null &&
        location.pathname != `/trip/${appStore.activeTrip}/description` &&
        location.pathname != `/trip/${appStore.activeTrip}/spots` &&
        location.pathname != `/trip/${appStore.activeTrip}/map` &&
        !location.pathname.includes('/activity') &&
        !uiStore.scrolledBottom && (
          <Card
            sx={{
              position: 'fixed',
              maxWidth: 500,
              bottom: 10,
              left: 16,
              right: 16,

              zIndex: 1000,
            }}
            variant="outlined"
          >
            <CardActionArea
              component={Link}
              to={`/trip/${appStore.activeTrip}/description`}
            >
              <Box sx={{ mb: -1 }}>
                <CachedImage
                  image={
                    appStore.trips.collection[appStore.activeTrip].profile_photo
                      .file_url
                  }
                  cacheName={`trip-${appStore.activeTrip}`}
                >
                  {(image) => (
                    <CardMedia
                      sx={{ height: 50, pt: 0.5 }}
                      image={image}
                      title={
                        appStore.activeTrip
                          ? appStore.trips.collection[appStore.activeTrip]
                              .profile_photo.file_name
                          : undefined
                      }
                    />
                  )}
                </CachedImage>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  px: 1,
                  py: 0.5,
                  borderBottomLeftRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <LocationOnIcon sx={{ fontSize: '1rem' }} />
                <Typography variant="caption" fontWeight="medium">
                  {stopsVisitedAlready}/{totalStops} míst
                </Typography>
              </Box>
              <CardHeader
                title={appStore.trips.collection[appStore.activeTrip].title}
                titleTypographyProps={{ variant: 'h6', fontSize: '1rem' }}
                sx={{ mt: 0.5, px: 2, pb: 1 }}
                action={
                  <Tooltip title="Smazat výlet">
                    <IconButton
                      onClick={handleOpenDialog}
                      size="small"
                      sx={{
                        color: 'text.secondary',
                        top: 1,
                        '&:hover': {
                          color: 'error.main',
                        },
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
              <CardContent sx={{ pb: 1, pt: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 0.5,
                  }}
                >
                  <TripInfo
                    icon={<AccessTimeIcon />}
                    tooltip="Časový odhad náročnosti cesty"
                    label={`${
                      appStore.trips.collection[appStore.activeTrip].duration
                    }hod.`}
                  />
                  <TripInfo
                    icon={<WalkIcon />}
                    label={`${
                      appStore.trips.collection[appStore.activeTrip].length
                    } km`}
                    tooltip="Přibližná délka celé cesty"
                  />
                  <Box
                    sx={{
                      ml: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={
                        progressPercentage === 100
                          ? 'success.main'
                          : 'text.secondary'
                      }
                      fontWeight="medium"
                    >
                      {progressPercentage}%
                    </Typography>
                  </Box>
                </Box>

                <LinearProgress
                  variant="determinate"
                  value={progressPercentage}
                  sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: 'grey.200',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 3,
                      backgroundColor:
                        progressPercentage === 100
                          ? 'success.main'
                          : 'primary.main',
                    },
                  }}
                />
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Zrušit trasu'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opravdu chcete smazat aktuální trasu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            Zrušit
          </Button>
          <Button
            onClick={handleDeactiveTrip}
            color="error"
            variant="outlined"
            autoFocus
          >
            Smazat
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
