import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../backend';
import uiStore from '../stores/ui';
import { Dialog, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme();

export default function ResetPassword() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    uiStore.appBar = {
      page: 'resetPassword',
    };

    window.scrollTo(0, 0);
  }, []);

  const { token } = useParams();

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [newPasswordLog, setNewPasswordLog] = React.useState('');
  const [newPasswordConfirmLog, setNewPasswordConfirmLog] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('white');

  const passwordChange = async () => {
    if (newPasswordLog == '' || newPasswordConfirmLog == '') {
      setMessageColor('red');
      setMessage('Vyplňte prosím všechny údaje.');
      return;
    }
    if (newPasswordLog == newPasswordConfirmLog) {
      const response = await resetPassword(token, newPasswordLog);
      if (response == 0) {
        setOpen(true);
      } else {
        setMessageColor('red');
        setMessage(
          'Při změně hesla nastala nečakaná chyba. Prosím kontaktujte podporu LApky.',
        );
      }
    } else {
      setMessageColor('red');
      setMessage('Hesla se musí zhodovat.');
    }
  };

  return (
    <Box
      sx={{
        padding: '10px',
        '& > div': {
          marginBottom: '10px',
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography align="center" color={messageColor}>
              {message}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Typography component="h1" variant="h5" sx={{ pt: 3 }}>
                Nové heslo
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="Nové heslo"
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                onChange={(e) => {
                  setNewPasswordLog(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography component="h1" variant="h5" sx={{ pt: 3 }}>
                Potvrdit heslo
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPasswordConfirm"
                label="Potvrdit heslo"
                type={showPasswordConfirm ? 'text' : 'password'}
                id="newPasswordConfirm"
                onChange={(e) => {
                  setNewPasswordConfirmLog(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                      >
                        {showPasswordConfirm ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={passwordChange}
              >
                Potvrdit
              </Button>
            </Box>
          </Box>
          <Dialog open={open} PaperProps={{ sx: { width: '80%' } }}>
            <IconButton
              sx={{ position: 'absolute', top: 3, right: 3 }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 70 }} />
              <Typography variant="h5" align="center">
                Heslo úspěšne změněno.
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={() => navigate('/signIn')}
              >
                OK
              </Button>
            </Box>
          </Dialog>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
