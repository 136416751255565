import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slider,
  ToggleButtonGroup,
} from '@mui/material';
import { Fab } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import StrollerIcon from '@mui/icons-material/Stroller';
import NoStrollerIcon from '@mui/icons-material/NoStroller';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography/Typography';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import FilterButton from './FilterButton';

export default function Filter({
  stroller,
  setStroller,
  lock,
  setLock,
  transportation,
  setTransportation,
  duration,
  setDuration,
  length,
  setLength,
  maxDuration,
  maxLength,
}: {
  stroller: string;
  setStroller: any;
  lock: string;
  setLock: any;
  transportation: string;
  setTransportation: any;
  duration: number;
  setDuration: any;
  length: number;
  setLength: any;
  maxDuration: number;
  maxLength: number;
}) {
  const [open, setOpen] = useState(false);

  const handleStroller = (
    event: React.MouseEvent<HTMLElement>,
    nextStroller: string,
  ) => {
    setStroller(nextStroller);
  };

  const handleLock = (
    event: React.MouseEvent<HTMLElement>,
    nextLock: string,
  ) => {
    setLock(nextLock);
  };

  const handleTransportation = (
    event: React.MouseEvent<HTMLElement>,
    nextTransportation: string,
  ) => {
    setTransportation(nextTransportation);
  };

  const handleDuration = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setDuration(newValue);
    }
  };

  const handleLength = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setLength(newValue);
    }
  };

  const hourMarks = [
    { value: 1, label: '1h' },
    { value: maxDuration, label: 'Bez omezení' },
  ];

  const lengthMarks = [
    { value: 1, label: '1km' },
    { value: maxLength, label: 'Bez omezení' },
  ];

  function hourText(value: number) {
    return `${value}h`;
  }

  function lengthText(value: number) {
    return `${value}km`;
  }

  function resetFilter() {
    setStroller('');
    setLock('');
    setTransportation('');
    setDuration(maxDuration);
    setLength(maxLength);
  }

  const ToggleButton = styled(MuiToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: '#1976d2',
    },
  });

  const [isScrolled, setIsScrolled] = useState(false);

  React.useEffect(() => {
    const checkScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollPosition > 200);
    };

    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return (
    <div style={{ marginTop: '5px' }}>
      <Button
        size="small"
        variant="outlined"
        startIcon={<FilterAltIcon />}
        onClick={() => setOpen(true)}
      >
        Filtrovat trasy
      </Button>
      {isScrolled && <FilterButton setOpen={setOpen} />}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: '90%', height: '85%' } }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 3, right: 3 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="Nastavení filtrování" textAlign="center">
          Nastavení filtrování
        </DialogTitle>
        <Stack spacing={1} alignItems="center">
          <Typography>Vhodné pro kočárky</Typography>
          <ToggleButtonGroup
            orientation="horizontal"
            value={stroller}
            exclusive
            onChange={handleStroller}
          >
            <ToggleButton
              value="noStroller"
              aria-label="noStroller"
              size="large"
            >
              <NoStrollerIcon />
            </ToggleButton>
            <ToggleButton
              value="strollerOnly"
              aria-label="strollerOnly"
              size="large"
            >
              <StrollerIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography>Zámek</Typography>
          <ToggleButtonGroup
            orientation="horizontal"
            value={lock}
            exclusive
            onChange={handleLock}
          >
            <ToggleButton value="unlocked" aria-label="unlocked" size="large">
              <LockOpenIcon />
            </ToggleButton>
            <ToggleButton value="locked" aria-label="locked" size="large">
              <LockIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography>Způsob dopravy</Typography>
          <ToggleButtonGroup
            orientation="horizontal"
            value={transportation}
            exclusive
            onChange={handleTransportation}
          >
            <ToggleButton value="walk" aria-label="walk" size="large">
              <DirectionsWalkIcon />
            </ToggleButton>
            <ToggleButton value="bike" aria-label="bike" size="large">
              <DirectionsBikeIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography sx={{ pt: 2, width: '65%' }} textAlign="center">
            Odhadovaný čas
          </Typography>
          <Slider
            aria-label="Odhadovaný čas"
            defaultValue={maxDuration}
            value={duration}
            getAriaValueText={hourText}
            valueLabelDisplay="auto"
            aria-labelledby="discrete-slider-small-steps"
            step={0.5}
            marks={hourMarks}
            min={1}
            max={maxDuration}
            onChange={handleDuration}
            sx={{ width: '70%' }}
          />
          <Typography>Vzdálenost</Typography>
          <Slider
            aria-label="Vzdálenost"
            defaultValue={maxLength}
            value={length}
            getAriaValueText={lengthText}
            valueLabelDisplay="auto"
            step={1}
            marks={lengthMarks}
            min={1}
            max={maxLength}
            onChange={handleLength}
            sx={{ width: '70%' }}
          />
          <Stack
            spacing={2}
            textAlign="center"
            justifyContent="center"
            sx={{ pt: 4, pb: 3, width: '65%' }}
          >
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Uložit
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
}
