import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@mui/material';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
  onConfirm: () => void;
  error?: string;
};

const ConfirmDialog = ({
  open,
  title,
  content,
  onConfirm,
  error,
  onClose,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          p: 2,
        },
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Typography>{content}</Typography>
        {error && (
          <>
            <Divider sx={{ mt: 2 }} />
            <Typography color="error" sx={{ py: 2 }}>
              {error}
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} sx={{ borderRadius: 2 }}>
          Zrušit
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={{ borderRadius: 2 }}
        >
          Potvrdit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
