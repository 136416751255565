import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  Card,
  CardMedia,
  IconButton,
  CardActions,
  CardActionArea,
  Button,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DirectionIcon from '@mui/icons-material/Directions';
import appStore from '../stores/app';
import uiStore from '../stores/ui';
import { getDistance } from '../utils/utils';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import { CachedImage } from '../components/CachedImage';
import { observer } from 'mobx-react-lite';
import ActionButton from './ui/ActionButton';
import TripInfo from './trip/TripInfo';

export const SpotCard = observer(function SpotCard({ id }: { id: number }) {
  const { title, gps, photo, serial_number, id_trip } = appStore.trips.spot[id];
  const isVisited = appStore.isSpotVisited(id);
  const distanceFromUser = Math.round(
    getDistance(gps, uiStore.currentUserPosition),
  );
  const distanceFromUserKm = distanceFromUser / 1000;

  return (
    <Card>
      <CardActionArea component={Link} to={`/spot/${id}`}>
        <CachedImage image={photo.photo_url} cacheName={`trip-${id_trip}`}>
          {(image) => (
            <CardMedia
              style={{ height: 140 }}
              image={image}
              title={photo.photo_name}
            />
          )}
        </CachedImage>
        <CardHeader title={`${serial_number}. ${title}`} />
      </CardActionArea>

      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ActionButton
          style={{ padding: 1.5, color: isVisited ? 'default' : 'primary' }}
          aria-label="Navigace"
          size="small"
          startIcon={<DirectionIcon />}
          onClick={() =>
            window.open(
              `https://www.google.com/maps/dir/?api=1&destination=${gps.lat},${gps.lng}&dir_action=navigate&travelmode=walking`,
            )
          }
        >
          &nbsp; Online navigace
          <br />
          Google
        </ActionButton>

        {isVisited ? (
          <IconButton
            style={{ paddingTop: 0, marginLeft: 'auto' }}
            aria-label="Místo bylo navštíveno"
            size="large"
          >
            <span style={{ fontSize: 14 }}>navštíveno</span>&nbsp; <DoneIcon />
          </IconButton>
        ) : (
          distanceFromUser > 0 && (
            <TripInfo
              tooltip={`Vzdálenost přímou čarou: ${distanceFromUserKm.toFixed(
                1,
              )}km`}
              label={`${distanceFromUserKm.toFixed(1)}km`}
              icon={<WalkIcon />}
            />
          )
        )}
      </CardActions>
    </Card>
  );
});
