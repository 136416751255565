import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { filesize } from 'filesize';

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Button,
  Dialog,
  Rating,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  Chip,
  Stack,
  Fade,
  Tooltip,
  CircularProgress,
  Divider,
  useTheme,
  alpha,
} from '@mui/material';

import {
  AccessTime as AccessTimeIcon,
  DirectionsWalk as WalkIcon,
  ChildFriendly as ChildIcon,
  DirectionsBike as BikeIcon,
  GetApp as GetAppIcon,
  OfflinePin as OfflinePinIcon,
  Star as StarIcon,
  Mail as MailIcon,
  Share as ShareIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import appStore from '../../stores/app';
import uiStore from '../../stores/ui';
import { CachedHtmlView } from '../../components/CachedHtmlView';
import { CachedImage } from '../../components/CachedImage';
import { cacheTrip, getTripFiles, removeTripFromCache } from '../../storage';
import { sendEmail, setRating } from '../../backend';
import { getRatingColor } from '../../utils/utils';
import TripInfo from '../../components/trip/TripInfo';
import ActionButton from '../../components/ui/ActionButton';
import { RatingDialog } from '../../components/ui/RatingDialog';
import { useToastContext } from '../../context/ToastContext';
import { EMAIL_TEMPLATES } from '../../components/EmailTemplates';

interface Trip {
  title: string;
  profile_photo: {
    file_url: string;
    file_name: string;
  };
  motivation: string;
  description: string;
  duration: number;
  length: number;
  baby_carriage: boolean;
  bicycle: boolean;
  author: string;
  ratings: any[];
  spotsAlwaysShown: boolean;
}

interface FormState {
  rating: number | null;
  email: string;
  message: string;
}

export default observer(function Description() {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const tripId = parseInt(id ?? '', 10);
  if (!tripId || isNaN(tripId)) return null;

  const trip = appStore.trips.collection[tripId] as Trip;
  const cached = appStore.trips.cached[tripId];
  const { list } = appStore.getTripScore(tripId);
  const stopsVisitedAlready = list.filter(Boolean).length;

  const [dialogs, setDialogs] = useState({
    rating: false,
    contact: false,
  });
  const [formState, setFormState] = useState<FormState>({
    rating: null,
    email: appStore.email || '',
    message: '',
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const { showToast } = useToastContext();

  const averageRating = appStore.getTripRatingAverage(tripId);
  const ratingCount = trip.ratings.length;

  const handleDialogClose = (dialog: 'rating' | 'contact') => {
    setDialogs((prev) => ({ ...prev, [dialog]: false }));
    setShowSuccess(false);
    if (dialog === 'rating') {
      setFormState((prev) => ({ ...prev, rating: null }));
    } else {
      setFormState((prev) => ({ ...prev, message: '' }));
    }
  };

  const handleSubmitRating = () => {
    if (formState.rating != null && appStore.user != null) {
      setRating(formState.rating, tripId, appStore.user);
      setShowSuccess(true);
      setTimeout(() => handleDialogClose('rating'), 4000);
    }
  };

  const handleContactAuthor = async () => {
    if (formState.email && formState.message) {
      const { subject, content } = EMAIL_TEMPLATES.CONTACT_AUTHOR({
        senderEmail: formState.email,
        tripTitle: trip.title,
        message: formState.message,
        tripId: tripId,
      });

      await sendEmail(formState.email, trip.author, content, subject);
      setShowSuccess(true);
      setTimeout(() => handleDialogClose('contact'), 2000);
    }
  };

  const handleShare = async () => {
    const shareData = { title: trip.title, url: window.location.href };
    try {
      if (navigator.canShare?.(shareData)) {
        await navigator.share(shareData);
      }
    } catch (error) {
      console.error('Sharing failed:', error);
    }
  };

  const handleActivateTrip = () => {
    if (appStore.activeTrip === tripId) {
      appStore.deactivateTrip();
    } else {
      showToast('Aktivována nová trasa, GPS aktivní', 'success');
      appStore.makeTripActive(tripId);
    }
  };

  const handleShowSpots = () => {
    if (appStore.trips.showSpots[tripId]) {
      appStore.hideTripSpots(tripId);
    } else {
      showToast('Vyzobrazeny všechny body trasy', 'success');
      appStore.viewTripSpots(tripId);
    }
  };

  return (
    <Fade in timeout={500}>
      <Card elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
        <CachedImage
          image={trip.profile_photo.file_url}
          cacheName={`trip-${tripId}`}
        >
          {(image) => (
            <CardMedia
              component="img"
              height="300"
              image={image}
              alt={trip.profile_photo.file_name}
              sx={{
                objectFit: 'cover',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            />
          )}
        </CachedImage>

        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'start', sm: 'space-between' },
              alignItems: { xs: 'start', sm: 'start' },
              gap: { xs: 2, sm: 0 },
              mb: 3,
            }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {trip.title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Navštíveno {stopsVisitedAlready} zastavení z {list.length}
              </Typography>
            </Box>

            {cached ? (
              <Tooltip title="Staženo offline">
                <IconButton
                  color="primary"
                  onClick={() => removeTripFromCache(tripId)}
                >
                  <OfflinePinIcon />
                </IconButton>
              </Tooltip>
            ) : !uiStore.hasConnectionIssue() ? (
              <Button
                startIcon={
                  uiStore.downloadCachePending ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GetAppIcon />
                  )
                }
                onClick={() => cacheTrip(tripId)}
                variant="outlined"
              >
                Stáhnout offline (
                {filesize(getTripFiles(tripId).length * 15000) as string})
              </Button>
            ) : null}
          </Box>

          <Typography variant="body1" sx={{ mb: 2 }}>
            <CachedHtmlView cacheName={`trip-${tripId}`}>
              {trip.motivation}
            </CachedHtmlView>
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
            <TripInfo
              icon={<AccessTimeIcon />}
              label={`${trip.duration} hod.`}
              tooltip="Časový odhad náročnosti cesty"
            />
            <TripInfo
              icon={<WalkIcon />}
              label={`${trip.length} km`}
              tooltip="Přibližná délka celé cesty"
            />
            {trip.baby_carriage && (
              <TripInfo
                icon={<ChildIcon />}
                label=""
                tooltip="Vhodné pro kočárky"
              />
            )}
            {trip.bicycle && (
              <TripInfo
                icon={<BikeIcon />}
                label=""
                tooltip="Vhodné pro jízdu na kole"
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { xs: 2, sm: 3 },
              mb: 3,
              alignItems: 'start',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                minWidth: { sm: '120px' },
              }}
            >
              <ActionButton
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {ratingCount >= 5 ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            color: getRatingColor(averageRating),
                            transition: 'color 0.3s ease',
                          }}
                        >
                          <StarIcon sx={{ color: 'inherit' }} />
                          <Typography
                            variant="h5"
                            sx={{
                              color: 'inherit',
                              fontWeight: 'bold',
                            }}
                          >
                            {averageRating}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 0.5,
                            color: 'text.secondary',
                            display: 'block',
                            textAlign: 'center',
                          }}
                        >
                          {ratingCount} hodnocení
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <StarIcon sx={{ color: 'inherit' }} />
                        <Typography>Ohodnotit</Typography>
                      </Box>
                    )}
                  </Box>
                }
                onClick={() =>
                  setDialogs((prev) => ({ ...prev, rating: true }))
                }
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { sm: 'flex-start' },
                alignItems: 'flex-start',
              }}
            >
              {trip.author && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'flex-start' },
                  }}
                >
                  <ActionButton
                    icon={<MailIcon />}
                    label="Kontaktovat autora"
                    onClick={() =>
                      setDialogs((prev) => ({ ...prev, contact: true }))
                    }
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                }}
              >
                <ActionButton
                  icon={<ShareIcon />}
                  label="Sdílet"
                  onClick={handleShare}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={appStore.activeTrip === tripId}
                  onChange={handleActivateTrip}
                />
              }
              label="Aktivovat trasu"
              sx={{
                mr: 3,
                '& .MuiFormControlLabel-label': {
                  color: 'text.secondary',
                },
              }}
            />
            {!trip.spotsAlwaysShown && (
              <FormControlLabel
                control={
                  <Switch
                    checked={appStore.trips.showSpots[tripId] || false}
                    onChange={handleShowSpots}
                  />
                }
                label="Zobrazit všechny body"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: 'text.secondary',
                  },
                }}
              />
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 4,
            }}
          >
            <Typography variant="h5" gutterBottom>
              Popis cesty
            </Typography>
            <Typography component="div">
              <CachedHtmlView cacheName={`trip-${tripId}`}>
                {trip.description}
              </CachedHtmlView>
            </Typography>
          </Box>
        </CardContent>

        {/* Rating Dialog */}
        <RatingDialog
          open={dialogs.rating}
          onClose={() => handleDialogClose('rating')}
          onSubmit={handleSubmitRating}
          value={formState.rating}
          onChange={(rating) => setFormState((prev) => ({ ...prev, rating }))}
          showSuccess={showSuccess}
        />

        {/* Contact Dialog */}
        <Dialog
          open={dialogs.contact}
          onClose={() => handleDialogClose('contact')}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              p: 2,
            },
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 3, right: 3 }}
            onClick={() => handleDialogClose('contact')}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            {showSuccess ? (
              <Typography color="success.main" align="center" sx={{ my: 3 }}>
                Zpráva byla odeslána.
              </Typography>
            ) : (
              <>
                <Typography variant="h5" gutterBottom align="center">
                  Kontaktovat autora
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={(e) => e.preventDefault()}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Emailová adresa"
                    value={formState.email}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    type="email"
                    error={
                      formState.email !== '' && !formState.email.includes('@')
                    }
                    helperText={
                      formState.email !== '' && !formState.email.includes('@')
                        ? 'Neplatná emailová adresa'
                        : ''
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    label="Zpráva"
                    value={formState.message}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        message: e.target.value,
                      }))
                    }
                    error={
                      formState.message !== '' && formState.message.length < 10
                    }
                    helperText={
                      formState.message !== '' && formState.message.length < 10
                        ? 'Zpráva musí obsahovat alespoň 10 znaků'
                        : ''
                    }
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleContactAuthor}
                    disabled={
                      !formState.email.includes('@') ||
                      formState.message.length < 10
                    }
                    sx={{ mt: 2 }}
                  >
                    Odeslat zprávu
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Dialog>
      </Card>
    </Fade>
  );
});
